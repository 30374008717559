import React from 'react';
import { config } from '../../helpers';
import { MDBCardImage } from 'mdb-react-ui-kit';
import defaultFlower from './defaultFlower.jpg'

function Displayphoto(props) {

    const { categoriesPhoto } = props;

    const mainTrue = categoriesPhoto.filter(element => element.main === true)

    function display() {

        const show = mainTrue.map(show => { return (show.url) })

        if (show.length === 0) {
            return <MDBCardImage overlay
                src={defaultFlower}
                alt='...'
                position='top'
                height="280"
            >
            </MDBCardImage>

        } else {

            const response = (`${config.webUrl}/${show}`);

            return <MDBCardImage overlay
                src={response}
                alt='...'
                position='top'
                height="280"
            >
            </MDBCardImage>
        }
    }

    return <React.Fragment>

        {display(categoriesPhoto)}

    </React.Fragment >
}
export default (Displayphoto)