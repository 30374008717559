
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import countryList from '../../helpers/countryList';
import { failureAlert, successAlert } from '../../slices/alertSlice';
import { acknowledgeFailure, register, reset } from '../../slices/registerSlice';

export const RegisterForm = (props) => {
	const history = useHistory();
	const dispatch = useDispatch();

	const user = useSelector(state => state.user);
	const reg = useSelector(state => state.register);

	if (user.loggedIn) {
		dispatch(reset());
		history.goBack();
	}
	if (reg.success) {
		dispatch(successAlert(reg.message));
		dispatch(reset());
		history.push('/user/login');
	}

	if (reg.failure) {
		dispatch(failureAlert(reg.message || 'Something went wrong.'));
		dispatch(acknowledgeFailure());
  }

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [phoneCountryCode, setPhoneCountryCode] = useState('');
	const [phoneAreaCode, setPhoneAreaCode] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

	const [agreement, setAgreement] = useState(false);

	const valid = agreement && username && password.length>=8 && password == rePassword;
	const submit = () => {
		const payload = {
			username,
			email,
			phoneNumber: {
				countryCode: phoneCountryCode,
				areaCode: phoneAreaCode,
				number: phoneNumber
			},
			password,
			rePassword,
		}
		dispatch(register(payload));

		console.log(register);
	}

	return (<div className="register-content">
		<div key="username" className="mb-3">
			<label className="mb-2">Username <span className="text-danger">*</span></label>
			<input type="text" className="form-control fs-13px" placeholder="Username"
				value={username} onChange={e => setUsername(e.target.value)}
			/>
		</div>
		<div key="email" className="mb-3">
			<label className="mb-2">Email <span className="text-danger">*</span></label>
			<input type="email" className="form-control fs-13px" placeholder="Email address"
				value={email} onChange={e => setEmail(e.target.value)}
			/>
		</div>
		<div key="phoneNumber" className="mb-3">
			<label className="mb-2">Phone Number <span className="text-danger">*</span></label>
			<div className="row gx-3">
				<div className="col-md-4 mb-2 mb-md-0">
					<FormGroup>
						<Input type="select" name="select" id="exampleSelect" value={phoneCountryCode} onChange={e => setPhoneCountryCode(e.target.value)}>
							<option key="countryCode" value={''}>Country Code</option>
							{countryList.map((country, index) => <option key={`${country.country} (+${country.code}`} value={country.code}>{country.country} (+{country.code})</option>)}
						</Input>
					</FormGroup>
				</div>
				<div className="col-md-4">
					<input type="text" className="form-control fs-13px" placeholder="Area Code"
						value={phoneAreaCode} onChange={e => setPhoneAreaCode(e.target.value)}
					/>
				</div>
				<div className="col-md-4">
					<input type="text" className="form-control fs-13px" placeholder="Number"
						value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
					/>
				</div>
			</div>
		</div>
		<div className="mb-4">
			<label className="mb-2">Password <span className="text-danger">*</span></label>
			<input type="password" className="form-control fs-13px" placeholder="Password"
				value={password} onChange={e => setPassword(e.target.value)}
			/>
		</div>
		<div className="mb-3">
			<label className="mb-2">Re-enter Password <span className="text-danger">*</span></label>
			<input type="password" className="form-control fs-13px" placeholder="Re-enter Password"
				value={rePassword} onChange={e => setRePassword(e.target.value)}
			/>
			{password !== rePassword && <label style={{ color: 'red' }}>Password and Confirmation must be equal.</label>}
		</div>
		<div className="form-check mb-4">
			<input className="form-check-input" type="checkbox" value="" id="agreementCheckbox" checked={agreement} onChange={e => setAgreement(e.target.checked)} />
			<label className="form-check-label" htmlFor="agreementCheckbox">
				By clicking Sign Up, you agree to our <Link to="/user/register-v3">Terms</Link> and that you have read our <Link to="/user/register-v3">Data Policy</Link>, including our <Link to="/user/register-v3">Cookie Use</Link>.
			</label>
		</div>
		<div className="mb-4">
			<button className="btn btn-primary d-block w-100 btn-lg h-45px fs-13px" onClick={() => submit()} disabled={!valid} > Sign Up</button>
		</div>
		<div className="mb-4 pb-5">
			Already a member? Click <Link to="/user/login">here</Link> to login.
		</div>
		<hr className="bg-gray-600 opacity-2" />
		<p className="text-center text-gray-600">
			&copy; FlowraPortal All Right Reserved 2021
		</p>
	</div>);
}