
import { configureStore } from '@reduxjs/toolkit';
import alertReducer from '../slices/alertSlice.js';
import registerReducer from '../slices/registerSlice.js';
import userReducer from '../slices/userSlice.js';
import userListReducer from '../slices/userListSlice.js';
import prodReducer from '../slices/prodSlice.js';
import ProduktListeReducer from '../slices/ProduktListeSlice.js';
import CategoryReducer from '../slices/CategorySlice.js';
import orderListReducer from '../slices/orderListSlice.js';





export const store = configureStore({
  reducer: {
    alert: alertReducer,
    register: registerReducer,
    user: userReducer,
    userList: userListReducer,
    prod: prodReducer,
    produktliste : ProduktListeReducer,
    Category : CategoryReducer,
    orderList: orderListReducer,
  },
});
