import React, { useState, useEffect } from 'react';
import { authHeader, config } from '../../helpers';
import './categorieStyle.css';
import InputMask from 'react-input-mask';
import { failureAlert } from '../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import { warningAlert } from '../../slices/alertSlice';
import { getcategoryListe } from '../../slices/CategorySlice';


export default function UpdateCategories(props) {


  const { categoryuuid, oldCategoriesList, categorieName, index } = props;

  const dispatch = useDispatch();

  //state for Update Categories
  const [updatename, setupdatename] = useState("");
  const [initialCategoryName, setinitialCategoryName] = useState("");


  // start function to update on Swagger
  function update(updatename, uuid) {

    if (updatename === "" || updatename.length < "3") {
      dispatch(warningAlert(`The Name of Categorie at least Three characters `))

      return false
    }
    // if not update
    if (initialCategoryName === updatename) {

      return toggleState()

    } else {
      // to compare if the name already existing
      const oldCategoriesNameList = oldCategoriesList.map((category) => category.name);

      let updateNameInArray = [updatename]

      updateNameInArray = updateNameInArray.map(name => name.toLowerCase())

      let oldCategoriesName = oldCategoriesNameList.map(name => name.toLowerCase())

      for (var i = oldCategoriesName.length - 1; i >= 0; i--) {

        for (var j = 0; j < updateNameInArray.length; j++) {

          if (oldCategoriesName[i] === updateNameInArray[j]) {

            dispatch(warningAlert(`The name ${updatename} is already existing `))

            return false

          }
        }
      }
    }
    // to post update name
    const requestOptions = {

      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({

        name: updatename,
        uuid: uuid,

      })
    };

    return fetch(`${config.apiUrl}/Categories/UpdateCategory`, requestOptions).then(res => {
      res.json()
      if (!res.ok) { dispatch(failureAlert(res.statusText)) };

      dispatch(getcategoryListe());

    });
  }

  //toggle

  const [isEdit, setisEdit] = useState({ edit: true })
  let { edit } = isEdit;

  function toggleState() {

    setisEdit({
      edit: !edit
    });
    if (edit) {
      setinitialCategoryName({ categorieName }.categorieName);
      setupdatename({ categorieName: categorieName }.categorieName)

    }
  }


  // Update name

  function _exitWithOutUpdate() {

    return toggleState()

  }

  function renderUpdate() {
    return (
        <React.Fragment>
          <div className="input-group">
            <InputMask type="text" className={"form-control "} placeholder="Category"
                       value={updatename} onChange={(e) => {
              setupdatename(e.target.value)
            }}/>
            <button className={"btn btn-success"} title="Save" onClick={() => update(updatename, categoryuuid)}>
              <i className="ion ion-md-save "/></button>
            <button className={"btn btn-danger"} title="Close" onClick={() => _exitWithOutUpdate()}>
              <i className="ion ion-md-close"/></button>
          </div>


        </React.Fragment>
        )
    }

    // Display Categorie Name
    function randerCategorieName() {
        return (
            <React.Fragment>

                <span style={{fontWeight: "900", fontSize: "200%"}}>{categorieName}</span>

                <button title="Edit" style={{border: "none", backgroundColor: "initial"}} onClick={() => toggleState()}>
                    <i
                        className="icon-pencil text-success"/></button>

            </React.Fragment>
        )
    }


  return <React.Fragment>

    {edit ? randerCategorieName() : renderUpdate()}

  </React.Fragment>

}


// if edit false it will be display input to update 
