import React, { useState, useEffect, useRef } from 'react';
import { authHeader, config } from '../../helpers';
import { useDispatch } from 'react-redux';
import { warningAlert } from '../../slices/alertSlice';
import InputMask from 'react-input-mask';
import { failureAlert, successAlert } from '../../slices/alertSlice';
import { getcategoryListe } from '../../slices/CategorySlice';

export default function AddCategories(props) {
    const dispatch = useDispatch();
    // To call function getCategories to show add Categories
    const { oldCategoriesList, } = props;


    // State For add Categories
    const [addname, setaddname] = useState('');

    const addnameRef = useRef();


    useEffect(() => {
        addnameRef.current.focus();
    },[]);

    function onFirstInputKey(e) {
        // e.preventDefault()

        if (e.key === 'Enter') {

            return (addCategorie(addname), setaddname(""))

        }
    }
    // function for show all Categories and compare if add duplicate categories name
    const oldCategoriesNameList = oldCategoriesList?.map((category) => category.name);


    /////////////////////
    function addCategorie(addname) {
        console.log("addname ", addname);
        //////To compare Duplicates

        if (addname === "" || addname.length < "3") {
            dispatch(warningAlert(`The Name of Categorie at least Three characters `))

            return false
        }
        let addNameInArray = [addname]

        addNameInArray = addNameInArray.map(name => name.toLowerCase())

        let oldCategoriesName = oldCategoriesNameList.map(name => name.toLowerCase())

        for (var i = oldCategoriesName.length - 1; i >= 0; i--) {

            for (var j = 0; j < addNameInArray.length; j++) {

                if (oldCategoriesName[i] === addNameInArray[j]) {

                    dispatch(warningAlert(`The name ${addname} is already existing `))

                    return false

                }

            }
        }
        /////////
        const requestOptions = {
            method: 'POST',
            headers: authHeader(),
            body: JSON.stringify({
                name: addname,
            })
        };

        return fetch(`${config.apiUrl}/Categories/AddNewCategory`, requestOptions)

            .then(res => {
                if (!res.ok) { dispatch(failureAlert(res.statusText)) }
                else { dispatch(successAlert(`Successfully added category  ${addname} `)) }; return (res.json())
            })
            .then(setaddname(""))
            .then(res => { dispatch(getcategoryListe()) })

    }
    ///////////////////////
    return <React.Fragment>

        <div className="input-group mb-3">

            <input type="text" placeholder="Category Name" ref={addnameRef} onKeyUp={onFirstInputKey} className="form-control" value={addname} onChange={(e) => {
                setaddname(e.target.value)
            }} />
       
            <div className="input-group-append">
                <button onClick={() => {
                    return (addCategorie(addname), setaddname(""))
                }} className="btn btn-outline-success" type="button">Add Category
                </button>
            </div>
        </div>


    </React.Fragment>

}