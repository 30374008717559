import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToggle, Col, FormGroup, Input, Label, Table } from 'reactstrap';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel';
import { orderStatusArray } from '../../helpers/orderStatus';
import { getOrders, getUserOrders } from '../../slices/orderListSlice';
import { getUsersByType } from '../../slices/userListSlice';
import EditOrderStatus from './EditOrderStatus';

const OrderList = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const userList = useSelector(state => state.userList);
  const orderList = useSelector(state => state.orderList);
  const [filterStatus, setfilterStatus] = useState('');
  const [withClosed, setwithClosed] = useState(false);
  const [filterUserUuid, setfilterUser] = useState(user.userId);


  const applyFilter = () => {  
    if (filterUserUuid) dispatch(getUserOrders({
      userUuid: filterUserUuid,
      withClosed: withClosed,
      // status: filterStatus
    }));
    else dispatch(getOrders({
      withClosed: withClosed,
      // status: filterStatus
    }));
  }
  const reload = () => {
    dispatch(getOrders({
      withClosed
    }));
  }
  useEffect(() => {
    applyFilter();
  }, [filterUserUuid, withClosed]);
  useEffect(() => {
    if (!userList.items && !userList.loading && !userList.error && !userList.rejected) {
      dispatch(getUsersByType());
    }
  }, [userList.items]);

  return (
    <Panel>
      <PanelHeader noButton={true}>
        <div className="d-flex align-items-center">
          Orders
        </div>
      </PanelHeader>
      <PanelBody>
        <FormGroup row>
          <Label htmlFor="filterOrderStatus" sm="1">With Status:</Label>
          <Col sm="2">
            <Input type="select" name="selectStatus" id="filteOrderStatus"
              value={filterStatus} onChange={e=>setfilterStatus(e.target.value)}
            >
              <option value={''}>All</option>
              {orderStatusArray.map(status => <option key={status} value={status}>{status}</option>)}
            </Input>
          </Col>
          <Col sm="3">
            <Label htmlFor="withClosed" check sm="12">
              <Input type="checkbox" name="withClosed" id="withClosed"
                value={withClosed} onChange={e=>setwithClosed(e.target.checked)}
              /> Include Closed Orders
            </Label>
          </Col>
          {user.loggedIn && user.loaded && userList.items && <>
            <Label htmlFor="filterUser" sm="1">Orders from:</Label>
            <Col sm="2">
              <Input type="select" name="selectUser" id="filterUser"
                value={filterUserUuid} onChange={e=>setfilterUser(e.target.value)}
              >
                <option value={''}>All Users</option>
                {userList.items.map(userItem => <option key={userItem.id} value={userItem.id}>{userItem.userName}</option>)}
              </Input>
            </Col>
            <Col>
              {/* <Button onClick={() => applyFilter()}>Refresh List</Button> */}
            </Col>
          </>}
        </FormGroup>
        <br/>
        <Table striped bordered>
          <thead>
            <tr>
              <th scope="row" width="15%">Client</th>
              <th width="20%">Status</th>
              <th>Organizer</th>
            </tr>
          </thead>
          <tbody>
            {orderList.items && (filterStatus ? orderList.items.filter(item=>item.status == filterStatus) : orderList.items)
            .map((item, index) => 
              <tr key={item.id}>
                <td scope="row">{item.client.userName}</td>
                <td>
                  {/* {item.deliveryAddress.firstName} {item.deliveryAddress.lastName},{' '}
                  {item.deliveryAddress.street} {item.deliveryAddress.streetNr},{' '}
                  {item.deliveryAddress.zipCode} {item.deliveryAddress.city}, {item.deliveryAddress.countryIsoCode} */}
                  {item.status}
                  {/* <EditOrderStatus orderUuid={item.id} orderStatus={item.status} /> */}
                </td>
                <td>
                  {item.organiser.userName}
                </td>
                {/* <td>
                  <button disabled={user.userId == item.id} className="btn btn-danger btn-xs">delete user</button>
                </td> */}
              </tr>
            )}
          </tbody>
        </Table>
        <br/>
      </PanelBody>
    </Panel>
  );
}

export default OrderList;