import React from 'react';
import  { useState, useEffect } from 'react';
import { authHeader, config } from '../../helpers';
import { FormGroup, Input, Label } from 'reactstrap';


function CatgoryTest(props) {

  const [CategoryState, setCategoryState] = useState([]);
 

  React.useEffect(() => {
  const requestOptions = {
      method:'GET',
      Headers: authHeader(),

};

 fetch(`${config.apiUrl}/Categories/GetCategoriesList`, requestOptions)
 .then(response => response.json())
 .then(( response ) => {
  setCategoryState(response);
});
}, []);

 
  

  const [selectedCategory, setselectedCategory] = useState('');


  return (
    <div>
    {selectedCategory}
    <FormGroup>
      <Label for="exampleSelect">Select</Label>
      <Input type="select" name="select" id="exampleSelect"
      value={selectedCategory} onChange={e => setselectedCategory(e.target.value)}>
        <option value={''} >Select a Category</option>
        {CategoryState.map((category, index) => <option value={category.uuid} >{category.name}</option>
        )}
      </Input>
    </FormGroup>
    <button onClick={()=>{
      
    }}>Add</button>




    </div>
  )
}

CatgoryTest.propTypes = {

}

export default CatgoryTest



