
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import { AppSettings } from '../../config/app-settings';
import { failureAlert } from '../../slices/alertSlice';
import { login, logout, reset } from '../../slices/userSlice';

const LoginForm = (props) => {
	const dispatch = useDispatch();
	const location = useLocation();
	const user = useSelector(state => state.user);

	const context = useContext(AppSettings);
  const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');

	// useEffect(() => {
	// 	// component did mount
	// 	context.handleSetAppSidebarNone(true);
	// 	context.handleSetAppHeaderNone(true);
	// 	context.handleSetAppContentClass('p-0');

	// 	// component will unmount
	// 	return () => {
	// 		context.handleSetAppSidebarNone(false);
	// 		context.handleSetAppHeaderNone(false);
	// 		context.handleSetAppContentClass('');
	// 	}
	// }, []);

	useEffect(() => {
		if (user.error) {
			dispatch(failureAlert(user.error));
			dispatch(reset());
		}
	}, [user.error])
	if (user.loggedIn) {
		return <Redirect from={'*'} to={location.state?.goBack || '/'} />
	}
	const submit = () => {
		dispatch(login({ username, password }));
	}
	return (<div className="login-content">
		<div className="form-floating mb-20px">
			<input type="text" className="form-control fs-13px h-45px border-0" placeholder="Username" id="username" value={username} onChange={e => setUsername(e.target.value)} />
			<label htmlFor="emailAddress" className="d-flex align-items-center text-gray-600 fs-13px" >Username</label>
			{user.validation?.Username?.map(error => <p style={{ color: 'red' }}>{error}</p>)}
		</div>
		<div className="form-floating mb-20px">
			<input type="password" className="form-control fs-13px h-45px border-0" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
			<label htmlFor="emailAddress" className="d-flex align-items-center text-gray-600 fs-13px" >Password</label>
			{user.validation?.Password?.map(error => <p style={{ color: 'red' }}>{error}</p>)}
		</div>
		{/*<div className="form-check mb-20px">
			<input className="form-check-input border-0" type="checkbox" value="1" id="rememberMe" />
			<label className="form-check-label fs-13px text-gray-500" htmlFor="rememberMe">
				Remember Me
			</label>
		</div>*/}
		<div className="mb-20px">
			<button className="btn btn-success d-block w-100 h-45px btn-lg" onClick={() => submit()}>Sign me in</button>
		</div>
		<div className="text-gray-500">
			Not a member yet? Click <Link to="/user/register">here</Link> to register.
		</div>
	</div>)
}

export default LoginForm;