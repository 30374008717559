import React from 'react';
import { Link } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import classnames from 'classnames';
import Highlight from 'react-highlight';

class UIIonicons extends React.Component {
	constructor(props) {
		super(props);

		this.toggle = this.toggle.bind(this);
		this.state = {
			activeTab: '1'
		};
	}

	toggle(tab) {
		if (this.state.activeTab !== tab) {
			this.setState({
				activeTab: tab
			});
		}
	}
  
	render() {
		return (
			<div>
				
			<ol className="breadcrumb float-xl-end">
				<li className="breadcrumb-item"><Link to="/ui/ionicons">Home</Link></li>
				<li className="breadcrumb-item"><Link to="/ui/ionicons">UI Elements</Link></li>
				<li className="breadcrumb-item active">Ionicons</li>
			</ol>
			<h1 className="page-header">Ionicons V2.0.1 <small>bundle with 696 icons (material & ios style icons)</small></h1>
			
			<Panel>
				<PanelHeader>Installation</PanelHeader>
				<PanelBody>
					<Nav pills>
						<NavItem>
							<NavLink
								className={'nav-link d-flex align-items-center ' + classnames({ active: this.state.activeTab === '1' })}
								onClick={() => { this.toggle('1'); }}
							>
								<i className="ion-logo-android fa-lg"></i><span className="d-none d-lg-inline ms-2">Material style (317)</span>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={'nav-link d-flex align-items-center ' + classnames({ active: this.state.activeTab === '2' })}
								onClick={() => { this.toggle('2'); }}
							>
								<i className="ion-logo-apple fa-lg"></i><span className="d-none d-lg-inline ms-2">IOS style (317)</span>
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={'nav-link d-flex align-items-center ' + classnames({ active: this.state.activeTab === '3' })}
								onClick={() => { this.toggle('3'); }}
							>
								<i className="ion-logo-ionic fa-lg"></i><span className="d-none d-lg-inline ms-2">Logos (62)</span>
							</NavLink>
						</NavItem>
					</Nav>
					<hr className="bg-gray-500" />
					<TabContent activeTab={this.state.activeTab}>
						<TabPane tabId="1">
							<div className="row" data-id="icon">
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-add-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-add-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-add-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-add-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-add fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-add</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-airplane fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-airplane</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-alarm fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-alarm</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-albums fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-albums</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-alert fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-alert</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-american-football fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-american-football</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-analytics fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-analytics</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-aperture fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-aperture</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-apps fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-apps</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-appstore fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-appstore</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-archive fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-archive</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-back fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-back</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropdown-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropdown-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropdown fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropdown</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropleft-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropleft-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropleft fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropleft</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropright-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropright-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropright fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropright</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropup-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropup-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-dropup fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-dropup</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-forward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-forward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-round-back fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-round-back</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-round-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-round-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-round-forward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-round-forward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-round-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-round-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-arrow-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-arrow-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-at fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-at</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-attach fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-attach</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-backspace fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-backspace</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-barcode fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-barcode</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-baseball fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-baseball</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-basket fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-basket</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-basketball fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-basketball</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-battery-charging fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-battery-charging</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-battery-dead fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-battery-dead</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-battery-full fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-battery-full</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-beaker fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-beaker</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bed fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bed</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-beer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-beer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bicycle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bicycle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bluetooth fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bluetooth</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-boat fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-boat</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-body fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-body</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bonfire fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bonfire</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-book fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-book</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bookmark fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bookmark</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bookmarks fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bookmarks</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bowtie fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bowtie</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-briefcase fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-briefcase</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-browsers fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-browsers</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-brush fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-brush</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bug fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bug</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-build fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-build</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bulb fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bulb</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-bus fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-bus</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-business fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-business</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cafe fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cafe</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-calculator fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-calculator</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-calendar fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-calendar</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-call fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-call</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-camera fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-camera</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-car fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-car</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-card fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-card</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cart fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cart</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cash fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cash</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cellular fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cellular</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-chatboxes fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-chatboxes</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-chatbubbles fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-chatbubbles</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-checkbox-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-checkbox-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-checkbox fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-checkbox</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-checkmark-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-checkmark-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-checkmark-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-checkmark-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-checkmark fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-checkmark</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-clipboard fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-clipboard</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-clock fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-clock</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-close-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-close-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-close-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-close-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-close fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-close</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloud-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloud-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloud-done fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloud-done</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloud-download fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloud-download</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloud-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloud-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloud-upload fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloud-upload</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloud fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloud</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloudy-night fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloudy-night</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cloudy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cloudy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-code-download fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-code-download</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-code-working fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-code-working</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-code fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-code</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cog fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cog</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-color-fill fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-color-fill</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-color-filter fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-color-filter</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-color-palette fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-color-palette</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-color-wand fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-color-wand</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-compass fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-compass</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-construct fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-construct</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-contact fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-contact</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-contacts fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-contacts</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-contract fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-contract</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-contrast fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-contrast</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-copy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-copy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-create fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-create</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-crop fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-crop</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cube fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cube</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-cut fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-cut</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-desktop fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-desktop</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-disc fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-disc</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-document fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-document</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-done-all fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-done-all</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-download fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-download</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-easel fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-easel</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-egg fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-egg</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-exit fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-exit</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-expand fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-expand</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-eye-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-eye-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-eye fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-eye</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-fastforward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-fastforward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-female fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-female</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-filing fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-filing</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-film fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-film</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-finger-print fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-finger-print</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-fitness fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-fitness</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-flag fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-flag</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-flame fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-flame</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-flash-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-flash-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-flash fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-flash</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-flashlight fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-flashlight</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-flask fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-flask</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-flower fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-flower</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-folder-open fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-folder-open</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-folder fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-folder</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-football fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-football</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-funnel fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-funnel</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-gift fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-gift</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-git-branch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-git-branch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-git-commit fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-git-commit</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-git-compare fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-git-compare</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-git-merge fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-git-merge</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-git-network fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-git-network</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-git-pull-request fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-git-pull-request</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-glasses fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-glasses</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-globe fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-globe</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-grid fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-grid</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-hammer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-hammer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-hand fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-hand</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-happy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-happy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-headset fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-headset</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-heart fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-heart</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-heart-dislike fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-heart-dislike</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-heart-empty fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-heart-empty</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-heart-half fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-heart-half</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-help-buoy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-help-buoy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-help-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-help-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-help-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-help-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-help fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-help</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-home fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-home</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-hourglass fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-hourglass</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-ice-cream fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-ice-cream</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-image fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-image</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-images fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-images</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-infinite fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-infinite</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-information-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-information-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-information-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-information-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-information fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-information</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-jet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-jet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-journal fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-journal</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-key fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-key</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-keypad fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-keypad</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-laptop fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-laptop</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-leaf fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-leaf</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-link fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-link</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-list-box fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-list-box</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-list fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-list</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-locate fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-locate</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-lock fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-lock</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-log-in fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-log-in</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-log-out fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-log-out</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-magnet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-magnet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-mail-open fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-mail-open</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-mail-unread fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-mail-unread</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-mail fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-mail</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-male fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-male</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-man fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-man</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-map fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-map</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-medal fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-medal</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-medical fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-medical</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-medkit fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-medkit</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-megaphone fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-megaphone</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-menu fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-menu</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-mic-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-mic-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-mic fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-mic</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-microphone fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-microphone</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-moon fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-moon</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-more fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-more</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-move fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-move</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-musical-note fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-musical-note</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-musical-notes fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-musical-notes</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-navigate fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-navigate</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-notifications-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-notifications-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-notifications-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-notifications-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-notifications fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-notifications</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-nuclear fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-nuclear</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-nutrition fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-nutrition</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-open fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-open</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-options fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-options</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-outlet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-outlet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-paper-plane fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-paper-plane</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-paper fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-paper</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-partly-sunny fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-partly-sunny</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pause fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pause</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-paw fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-paw</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-people fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-people</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-person-add fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-person-add</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-person fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-person</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-phone-landscape fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-phone-landscape</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-phone-portrait fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-phone-portrait</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-photos fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-photos</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pie fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pie</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pin fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pin</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pint fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pint</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pizza fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pizza</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-planet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-planet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-play-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-play-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-play fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-play</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-podium fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-podium</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-power fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-power</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pricetag fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pricetag</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pricetags fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pricetags</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-print fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-print</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-pulse fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-pulse</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-qr-scanner fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-qr-scanner</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-quote fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-quote</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-radio-button-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-radio-button-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-radio-button-on fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-radio-button-on</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-radio fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-radio</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-rainy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-rainy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-recording fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-recording</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-redo fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-redo</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-refresh-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-refresh-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-refresh fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-refresh</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-remove-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-remove-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-remove-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-remove-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-remove fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-remove</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-reorder fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-reorder</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-repeat fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-repeat</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-resize fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-resize</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-restaurant fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-restaurant</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-return-left fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-return-left</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-return-right fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-return-right</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-reverse-camera fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-reverse-camera</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-rewind fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-rewind</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-ribbon fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-ribbon</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-rocket fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-rocket</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-rose fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-rose</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-sad fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-sad</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-save fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-save</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-school fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-school</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-search fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-search</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-send fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-send</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-settings fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-settings</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-share-alt fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-share-alt</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-share fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-share</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-shirt fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-shirt</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-shuffle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-shuffle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-skip-backward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-skip-backward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-skip-forward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-skip-forward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-snow fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-snow</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-speedometer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-speedometer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-square-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-square-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-square fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-square</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-star-half fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-star-half</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-star-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-star-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-star fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-star</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-stats fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-stats</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-stopwatch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-stopwatch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-subway fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-subway</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-sunny fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-sunny</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-swap fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-swap</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-switch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-switch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-sync fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-sync</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-tablet-landscape fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-tablet-landscape</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-tablet-portrait fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-tablet-portrait</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-tennisball fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-tennisball</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-text fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-text</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-thermometer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-thermometer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-thumbs-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-thumbs-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-thumbs-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-thumbs-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-thunderstorm fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-thunderstorm</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-time fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-time</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-timer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-timer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-today fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-today</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-train fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-train</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-transgender fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-transgender</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-trash fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-trash</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-trending-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-trending-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-trending-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-trending-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-trophy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-trophy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-tv fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-tv</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-umbrella fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-umbrella</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-undo fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-undo</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-unlock fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-unlock</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-videocam fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-videocam</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-volume-high fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-volume-high</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-volume-low fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-volume-low</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-volume-mute fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-volume-mute</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-volume-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-volume-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-wallet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-wallet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-walk fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-walk</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-warning fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-warning</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-watch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-watch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-water fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-water</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-wifi fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-wifi</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-wine fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-wine</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-md-woman fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-md-woman</div></div>
							</div>
						</TabPane>
						<TabPane tabId="2">
							<div className="row">
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-add-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-add-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-add-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-add-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-add fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-add</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-airplane fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-airplane</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-alarm fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-alarm</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-albums fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-albums</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-alert fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-alert</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-american-football fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-american-football</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-analytics fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-analytics</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-aperture fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-aperture</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-apps fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-apps</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-appstore fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-appstore</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-archive fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-archive</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-back fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-back</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropdown-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropdown-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropdown fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropdown</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropleft-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropleft-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropleft fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropleft</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropright-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropright-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropright fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropright</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropup-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropup-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-dropup fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-dropup</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-forward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-forward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-round-back fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-round-back</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-round-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-round-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-round-forward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-round-forward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-round-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-round-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-arrow-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-arrow-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-at fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-at</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-attach fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-attach</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-backspace fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-backspace</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-barcode fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-barcode</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-baseball fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-baseball</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-basket fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-basket</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-basketball fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-basketball</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-battery-charging fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-battery-charging</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-battery-dead fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-battery-dead</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-battery-full fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-battery-full</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-beaker fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-beaker</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bed fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bed</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-beer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-beer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bicycle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bicycle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bluetooth fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bluetooth</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-boat fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-boat</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-body fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-body</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bonfire fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bonfire</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-book fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-book</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bookmark fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bookmark</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bookmarks fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bookmarks</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bowtie fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bowtie</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-briefcase fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-briefcase</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-browsers fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-browsers</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-brush fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-brush</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bug fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bug</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-build fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-build</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bulb fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bulb</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-bus fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-bus</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-business fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-business</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cafe fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cafe</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-calculator fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-calculator</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-calendar fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-calendar</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-call fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-call</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-camera fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-camera</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-car fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-car</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-card fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-card</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cart fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cart</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cash fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cash</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cellular fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cellular</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-chatboxes fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-chatboxes</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-chatbubbles fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-chatbubbles</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-checkbox-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-checkbox-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-checkbox fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-checkbox</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-checkmark-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-checkmark-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-checkmark-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-checkmark-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-checkmark fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-checkmark</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-clipboard fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-clipboard</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-clock fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-clock</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-close-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-close-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-close-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-close-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-close fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-close</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloud-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloud-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloud-done fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloud-done</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloud-download fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloud-download</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloud-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloud-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloud-upload fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloud-upload</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloud fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloud</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloudy-night fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloudy-night</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cloudy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cloudy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-code-download fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-code-download</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-code-working fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-code-working</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-code fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-code</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cog fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cog</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-color-fill fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-color-fill</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-color-filter fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-color-filter</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-color-palette fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-color-palette</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-color-wand fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-color-wand</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-compass fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-compass</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-construct fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-construct</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-contact fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-contact</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-contacts fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-contacts</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-contract fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-contract</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-contrast fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-contrast</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-copy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-copy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-create fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-create</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-crop fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-crop</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cube fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cube</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-cut fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-cut</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-desktop fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-desktop</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-disc fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-disc</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-document fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-document</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-done-all fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-done-all</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-download fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-download</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-easel fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-easel</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-egg fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-egg</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-exit fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-exit</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-expand fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-expand</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-eye-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-eye-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-eye fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-eye</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-fastforward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-fastforward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-female fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-female</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-filing fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-filing</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-film fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-film</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-finger-print fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-finger-print</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-fitness fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-fitness</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-flag fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-flag</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-flame fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-flame</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-flash-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-flash-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-flash fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-flash</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-flashlight fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-flashlight</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-flask fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-flask</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-flower fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-flower</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-folder-open fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-folder-open</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-folder fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-folder</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-football fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-football</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-funnel fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-funnel</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-gift fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-gift</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-git-branch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-git-branch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-git-commit fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-git-commit</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-git-compare fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-git-compare</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-git-merge fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-git-merge</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-git-network fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-git-network</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-git-pull-request fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-git-pull-request</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-glasses fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-glasses</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-globe fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-globe</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-grid fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-grid</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-hammer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-hammer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-hand fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-hand</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-happy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-happy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-headset fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-headset</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-heart fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-heart</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-heart-dislike fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-heart-dislike</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-heart-empty fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-heart-empty</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-heart-half fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-heart-half</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-help-buoy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-help-buoy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-help-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-help-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-help-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-help-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-help fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-help</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-home fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-home</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-hourglass fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-hourglass</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-ice-cream fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-ice-cream</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-image fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-image</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-images fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-images</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-infinite fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-infinite</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-information-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-information-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-information-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-information-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-information fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-information</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-jet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-jet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-journal fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-journal</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-key fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-key</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-keypad fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-keypad</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-laptop fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-laptop</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-leaf fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-leaf</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-link fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-link</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-list-box fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-list-box</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-list fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-list</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-locate fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-locate</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-lock fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-lock</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-log-in fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-log-in</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-log-out fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-log-out</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-magnet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-magnet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-mail-open fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-mail-open</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-mail-unread fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-mail-unread</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-mail fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-mail</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-male fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-male</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-man fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-man</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-map fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-map</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-medal fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-medal</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-medical fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-medical</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-medkit fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-medkit</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-megaphone fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-megaphone</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-menu fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-menu</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-mic-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-mic-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-mic fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-mic</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-microphone fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-microphone</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-moon fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-moon</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-more fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-more</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-move fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-move</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-musical-note fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-musical-note</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-musical-notes fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-musical-notes</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-navigate fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-navigate</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-notifications-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-notifications-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-notifications-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-notifications-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-notifications fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-notifications</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-nuclear fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-nuclear</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-nutrition fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-nutrition</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-open fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-open</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-options fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-options</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-outlet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-outlet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-paper-plane fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-paper-plane</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-paper fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-paper</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-partly-sunny fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-partly-sunny</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pause fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pause</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-paw fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-paw</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-people fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-people</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-person-add fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-person-add</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-person fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-person</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-phone-landscape fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-phone-landscape</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-phone-portrait fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-phone-portrait</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-photos fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-photos</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pie fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pie</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pin fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pin</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pint fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pint</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pizza fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pizza</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-planet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-planet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-play-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-play-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-play fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-play</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-podium fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-podium</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-power fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-power</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pricetag fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pricetag</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pricetags fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pricetags</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-print fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-print</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-pulse fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-pulse</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-qr-scanner fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-qr-scanner</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-quote fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-quote</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-radio-button-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-radio-button-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-radio-button-on fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-radio-button-on</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-radio fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-radio</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-rainy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-rainy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-recording fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-recording</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-redo fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-redo</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-refresh-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-refresh-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-refresh fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-refresh</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-remove-circle-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-remove-circle-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-remove-circle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-remove-circle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-remove fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-remove</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-reorder fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-reorder</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-repeat fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-repeat</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-resize fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-resize</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-restaurant fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-restaurant</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-return-left fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-return-left</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-return-right fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-return-right</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-reverse-camera fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-reverse-camera</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-rewind fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-rewind</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-ribbon fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-ribbon</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-rocket fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-rocket</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-rose fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-rose</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-sad fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-sad</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-save fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-save</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-school fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-school</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-search fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-search</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-send fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-send</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-settings fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-settings</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-share-alt fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-share-alt</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-share fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-share</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-shirt fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-shirt</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-shuffle fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-shuffle</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-skip-backward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-skip-backward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-skip-forward fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-skip-forward</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-snow fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-snow</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-speedometer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-speedometer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-square-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-square-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-square fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-square</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-star-half fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-star-half</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-star-outline fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-star-outline</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-star fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-star</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-stats fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-stats</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-stopwatch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-stopwatch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-subway fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-subway</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-sunny fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-sunny</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-swap fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-swap</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-switch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-switch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-sync fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-sync</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-tablet-landscape fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-tablet-landscape</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-tablet-portrait fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-tablet-portrait</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-tennisball fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-tennisball</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-text fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-text</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-thermometer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-thermometer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-thumbs-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-thumbs-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-thumbs-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-thumbs-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-thunderstorm fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-thunderstorm</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-time fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-time</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-timer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-timer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-today fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-today</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-train fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-train</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-transgender fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-transgender</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-trash fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-trash</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-trending-down fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-trending-down</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-trending-up fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-trending-up</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-trophy fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-trophy</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-tv fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-tv</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-umbrella fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-umbrella</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-undo fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-undo</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-unlock fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-unlock</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-videocam fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-videocam</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-volume-high fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-volume-high</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-volume-low fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-volume-low</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-volume-mute fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-volume-mute</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-volume-off fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-volume-off</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-wallet fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-wallet</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-walk fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-walk</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-warning fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-warning</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-watch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-watch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-water fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-water</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-wifi fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-wifi</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-wine fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-wine</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-ios-woman fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-ios-woman</div></div>
							</div>
						</TabPane>
						<TabPane tabId="3">
							<div className="row">
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-android fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-android</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-angular fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-angular</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-apple fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-apple</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-bitbucket fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-bitbucket</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-bitcoin fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-bitcoin</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-buffer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-buffer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-chrome fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-chrome</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-closed-captioning fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-closed-captioning</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-codepen fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-codepen</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-css3 fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-css3</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-designernews fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-designernews</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-dribbble fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-dribbble</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-dropbox fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-dropbox</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-euro fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-euro</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-facebook fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-facebook</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-flickr fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-flickr</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-foursquare fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-foursquare</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-freebsd-devil fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-freebsd-devil</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-game-controller-a fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-game-controller-a</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-game-controller-b fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-game-controller-b</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-github fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-github</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-google fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-google</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-googleplus fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-googleplus</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-hackernews fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-hackernews</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-html5 fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-html5</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-instagram fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-instagram</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-ionic fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-ionic</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-ionitron fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-ionitron</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-javascript fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-javascript</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-linkedin fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-linkedin</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-markdown fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-markdown</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-model-s fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-model-s</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-no-smoking fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-no-smoking</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-nodejs fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-nodejs</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-npm fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-npm</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-octocat fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-octocat</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-pinterest fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-pinterest</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-playstation fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-playstation</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-polymer fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-polymer</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-python fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-python</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-reddit fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-reddit</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-rss fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-rss</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-sass fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-sass</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-skype fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-skype</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-slack fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-slack</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-snapchat fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-snapchat</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-steam fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-steam</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-tumblr fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-tumblr</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-tux fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-tux</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-twitch fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-twitch</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-twitter fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-twitter</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-usd fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-usd</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-vimeo fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-vimeo</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-vk fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-vk</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-whatsapp fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-whatsapp</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-windows fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-windows</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-wordpress fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-wordpress</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-xbox fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-xbox</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-xing fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-xing</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-yahoo fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-yahoo</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-yen fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion-logo-yen</div></div>
								<div className="col-xl-3 col-lg-4 col-md-6 col-12 py-5px d-flex align-items-center"><i className="ion ion-logo-youtube fa-2x fa-fw float-start me-10px text-black-lighter"></i><div className="text-ellipsis">ion ion-logo-youtube</div></div>
							</div>
						</TabPane>
					</TabContent>
				</PanelBody>
				
				<div className="hljs-wrapper">
					<Highlight className='html'>{
'<!-- material -->\n'+
'<i className="ion ion-md-add-circle-outline"></i>\n'+
'\n'+
'<!-- ios -->\n'+
'<i className="ion ion-ios-add-circle-outline"></i>\n'+
'\n'+
'<!-- logo -->\n'+
'<i className="ion ion-logo-android"></i>'}
					</Highlight>
				</div>
			</Panel>
			</div>
		)
	}
}

export default UIIonicons;