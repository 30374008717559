import { privateRoutes, publicRoutes } from "../config/app-route";

export default function findRoute (path) {
	if ('string' == typeof path) {
		const basePath = path.split('?')[0];
		console.log(basePath);
		for (let i = 0; i < publicRoutes.length + privateRoutes.length; i++) {
			const route = (i < publicRoutes.length) ? publicRoutes[i] : privateRoutes[i - publicRoutes.length];
			if (route.path === basePath) {
				return {
					private: i >= publicRoutes.length,
					...route
				};
			}
		}
	}

	return null;
}