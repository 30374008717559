import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { RegisterForm } from './RegisterForm.jsx';

class RegisterV3 extends React.Component {
	static contextType = AppSettings;
	
	constructor(props) {
    super(props);
    
    this.handleSubmit = this.handleSubmit.bind(this);
  }

	componentDidMount() {
		this.context.handleSetAppSidebarNone(true);
		this.context.handleSetAppHeaderNone(true);
		this.context.handleSetAppContentClass('p-0');
	}

	componentWillUnmount() {
		this.context.handleSetAppSidebarNone(false);
		this.context.handleSetAppHeaderNone(false);
		this.context.handleSetAppContentClass('');
	}
	
	handleSubmit(event) {
		event.preventDefault();
    this.props.history.push('/dashboard/v3');
  }
  
	render() {
		return (
			<div className="register register-with-news-feed">
				<div className="news-feed">
					<div className="news-image" style={{ backgroundImage: 'url(/assets/img/login-bg/login-bg-9.jpg)' }}></div>
					<div className="news-caption">
						<h4 className="caption-title"><b>Flowra</b>Portal</h4>
						<p>
							FlowraApp Admin Dashboard
						</p>
					</div>
				</div>
				<div className="register-container">
					<div className="register-header mb-25px h1">
						<div className="mb-1">Sign Up</div>
						<small className="d-block fs-15px lh-16"></small>
					</div>
					<RegisterForm />
				</div>
			</div>	
		)
	}
}

export default withRouter(RegisterV3);