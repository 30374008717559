
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { authService } from '../services/authService';
import { authHeader } from '../helpers/authHeader';
import { config } from '../config/config';
import { clientType } from '../helpers/userTypes';

export const login = createAsyncThunk(
  'Authenticate/login',
  async (payload) => {
    // remove current user from localStorage (just in case) before logging in
    localStorage.clear();
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
        body: JSON.stringify({
            username: payload.username,
            password: payload.password
        })
      };
      return fetch(`${config.apiUrl}/Authenticate/login`, requestOptions).then(res => res.json());
  }
);

export const logout = createAsyncThunk(
  'Authenticate/logout',
  async (payload) => {
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify({})
    };
    // remove 'user' from local storage after authHeader has been created
    localStorage.clear();
    return fetch(`${config.apiUrl}/Authenticate/logout`, requestOptions).then(res => res.json());
  }
);

const initialState = {};
const loadedState = {
  loaded: true
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loadUser: (state, action) => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user && user.loggedIn && user.token && user.expiration) {
        if (new Date(user.expiration).valueOf() > new Date(Date.now()).valueOf())
          return { ...user, loaded: true };
      }
      localStorage.clear();
      return loadedState;
    },
    expired: (state, action) => {
      state.expired = true;
    },
    reset: () => {
      localStorage.clear();
      return loadedState;
    },
  },
  extraReducers: {
    // login reducers
    [login.pending]: (state, action) => {
      if (state.expired) state.loading = true;
      else return {
        username: action.meta.arg.username,
        pending: true,
        loaded: true,
      }
    },
    [login.rejected]: (state, action) => {
      return {
        error: action.error?.message ? action.error.message : action.error,
        loaded: true,
      }
    },
    [login.fulfilled]: (state, action) => {
      const payload = action.payload;
      switch (payload.status) {
        case 400:
          return {
            validation: payload.errors,
            loaded: true,
          }
        case 401:
          return {
            error: 'Unauthorized. Username or Password is wrong.',
            loaded: true,
          };
        case 200:
        default:
          if (payload.token) {
            if (payload.userType == clientType) return {
              error: 'Client-Users are not allowed to use this portal.',
              loaded: true,
            }
            const { userName, ...data } = payload;
            const user = {
              ...data,
              username: userName,
              loggedIn: true,
              loaded: true,
            };
            localStorage.setItem('user', JSON.stringify(user));
            //console.log('payload: ');
            //console.log(payload);
            //console.log('data: ');
            //console.log(data);
            //console.log('user: ');
            //console.log(user);
            
            return user;
          }
          return {
            error: 'Login unsuccessfull. You may try again. Error: Unexpected response from Server.',
            loaded: true,
          };
      }
    },
    // logout reducers - just resets state ánd ignores response
    [logout.pending]: (state, action) => {
      localStorage.removeItem('user');
      return loadedState;
    },
  }
});

// Action creators are generated for each case reducer function
// export const { login, logout, register, ...rest } = userSlice.actions;

export const { loadUser, expired, reset } = userSlice.actions;

export default userSlice.reducer;