import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { Link, Redirect, useHistory } from 'react-router-dom';
import { authHeader, config } from '../../helpers';
import { Modal, ModalBody } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { confirmAlert } from '../../slices/alertSlice';
import { useHistory , useLocation} from 'react-router';
import { useSelector } from "react-redux";
import { getcategoryListe } from "../../slices/CategorySlice";

import Product from './Product';



export default function ProductPage() {
    const dispatch = useDispatch();

const history =useHistory();

const location = useLocation();
// const CategoryState = useSelector((state) => state.Category);
const Category = useSelector((state) => state.Category.data);

const urlId1 = new URLSearchParams(location.search).get('categoriesUids');

console.log("urlidddddd",urlId1);
console.log("location" ,location);


    // const [arrProduct, setarrProduct] = useState([]);
    console.log('This is ProductPage');

    const [modalPosItem, setmodalPosItem] = useState(false);
    const [posMobileSidebarToggled, setposMobileSidebarToggled] = useState(false);




    // React.useEffect(() => {
    //     getProductArray()
    // }, []);



    useEffect(() => {
        // if (!Category && !CategoryState.status && !CategoryState.error) 
        
        dispatch(getcategoryListe());
    
        // dispatch(getProductById(urlId));
        // dispatch(getProductList());
      }, []);
    
	const togglePosMobileSidebar = () => {
		setposMobileSidebarToggled(!posMobileSidebarToggled);
	}
	const toggleModal = (name) => {
		switch (name) {
			case 'modalPosItem':	
                setmodalPosItem(!modalPosItem);
				break;
			default:
				break;
		}
	}
	const handleChange = () => {
	
	}

    
    // const getProductArray = () => {
    //     const requestOptions = {
    //         method: 'GET',
    //         headers: authHeader(),
    //         // body: JSON.stringify({
    //         // })
    //     };

    //     console.log('useEffect ProductPage!');

    //     // config.apiUrl+'/Products/GetProducts'
    //     fetch(`${config.apiUrl}/Products/GetProducts`, requestOptions)
    //         .then(response => response.json())
    //         .then(( response ) => {
    //             setarrProduct(response);
    //             console.log(setarrProduct);
    //         });
    // }

// console.log(getProductArray);
// console.log("Arrayprodukte",arrProduct);


    // ACHTUNG: ROUTE DeleteProduct IM BACKEND / SWAGGER NOCH NICHT IMPLEMENTIERT
    // const deleteProduct = (uuid) => {
    //     const requestOptions = {
    //         method: 'DELETE',
    //         headers: authHeader(),
    //         // body: JSON.stringify({
    //         // })
    //     };
    //     fetch(`${config.apiUrl}/Products/DeleteProduct?uuid=${encodeURIComponent(uuid)}`, requestOptions)
    //         .then(response => {
    //             getProductArray();
    //         });
    // }
    // const confirmDelete = (uuid) => {
    //     dispatch(confirmAlert({
    //         message: "Deleting this Product cannot be undone.",
    //         onConfirm: () => {
    //             deleteProduct(uuid);
    //         },
    //         // cancel: () => {

    //         // },
    //     }))
    // }

//     return (

//         <div>

//           <h1>Products</h1>

//           <div className="pos-content">
//                     <PerfectScrollbar className="pos-content-container" options={{suppressScrollX: true}}>
// 							<div className="product-row">
//                 {arrProduct.map(product =>

//                     // <li key={product.id}>{product.name}<Link to={`/product/ProductInfo?id=${product.id}`} >details</Link></li>

//                     <div className="product-container" data-type="drinks">
//                         <Link to={`/product/ProductInfo?id=${product.id}`} className="product" onClick={() => toggleModal('modalPosItem')}>
//                             <div className="img" style={{backgroundImage: 'url(/assets/img/pos/product-6.jpg)'}}></div>
//                             <div className="text">
//                                 <div className="title">{product.name}</div>
//                                 <div className="desc">avocado, milk, vanilla</div>
//                                 <div className="price">{product.price} {product.currency}</div>
//                             </div>
//                         </Link>
//                     </div>
//                 )}

//       </div>
//                         </PerfectScrollbar>
//                     </div>
//              <Link to='/'>Back to Homepage</Link>


           
//         </div>
        
//         );

    return (
      
        <div className="vh-100">
<div>

         <h1>Products</h1>

        {/* <div className="pos-content">
                   <PerfectScrollbar className="pos-content-container" options={{suppressScrollX: true}}>
							<div className="product-row">
                {arrProduct.map(product =>

                    // <li key={product.id}>{product.name}<Link to={`/product/ProductInfo?id=${product.id}`} >details</Link></li>

                    <div className="product-container" data-type="drinks">
                        <Link to={`/product/ProductInfo?id=${product.id}`} className="product" onClick={() => toggleModal('modalPosItem')}>
                            <div className="img" style={{backgroundImage: 'url(/assets/img/pos/product-6.jpg)'}}></div>
                            <div className="text">
                                <div className="title">{product.name}</div>
                                <div className="desc">avocado, milk, vanilla</div>
                                <div className="price">{product.price} {product.currency}</div>
                            </div>
                        </Link>
                    </div>
                )}

      </div>
                        </PerfectScrollbar>
                    </div> */}

             <Link to='/'>Back to Homepage</Link>
           
        </div>
        
        );



            <div className={'pos pos-customer ' + (posMobileSidebarToggled ? 'pos-mobile-sidebar-toggled' : '')} id="pos-customer">
                <div className="pos-menu">
                    <div className="logo">
                        <Link to="/">
                            <div className="logo-img"><img alt="" src="../assets/img/pos/logo.svg" /></div>
                            <div className="logo-text">Product & Details</div>
                        </Link>
                    </div>
                    <div className="nav-container">
                        <PerfectScrollbar className="height-full" options={{suppressScrollX: true}}>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <Link to="/pos/customer-order" className="nav-link active" data-filter="all">
                                        <i className="fa fa-fw fa-utensils mr-1 ml-n2"></i> All Product
                                    </Link>
                                </li>
                          
                            </ul>
                        </PerfectScrollbar>
                    </div>
                </div>
                
                <div className="pos-content">
                    <PerfectScrollbar className="pos-content-container" options={{suppressScrollX: true}}>
                        <div className="product-row">                
                        {Category && Category.map(product =>

                            // <li key={product.id}>{product.name}<Link to={`/product/ProductInfo?id=${product.id}`} >details</Link></li>

                            <div className="product-container" data-type="drinks">
                                <Link to={`/product/ProductInfo`} className="product" onClick={() => {
                                  
                                    toggleModal('modalPosItem');
                                    // add product to cart
                                    
                                }}>
                                    
                                    <div className="img" style={{backgroundImage: 'url(/assets/img/pos/product-6.jpg)'}} >
                                        <img src={`${config.webUrl}/images/categories/${product.uuid}.jpg`} width={200} height={250} />
                                    </div>
                                    <div className="text">
                                        <div className="title">{product.name}</div>
                                        <div className="desc">{}</div>
                                        <div className="price">{product.uuid} {}</div>
                                    </div>
                                </Link>
                                {/* <button onClick={()=>confirmDelete(product.uuid)} >Delete</button> */}
                            </div>
                            )}
                        </div>
                    </PerfectScrollbar>
                </div>
                
                <div className="pos-sidebar" id="pos-sidebar">
                   
                </div>
            </div>
            
             <Link to="/pos/customer-order" className="pos-mobile-sidebar-toggler" onClick={() => togglePosMobileSidebar()}>
                <svg viewBox="0 0 16 16" className="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" d="M14 5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5zM1 4v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4H1z"/>
                    <path d="M8 1.5A2.5 2.5 0 0 0 5.5 4h-1a3.5 3.5 0 1 1 7 0h-1A2.5 2.5 0 0 0 8 1.5z"/>
                </svg>
                <span className="badge">5</span>
            </Link>
            
            
            <Modal isOpen={modalPosItem} size="lg" toggle={() => toggleModal('modalPosItem')} modalClassName="modal-pos-item">
                <ModalBody className="p-0">
                    <Link to="/pos/customer-order" onClick={() => toggleModal('modalPosItem')} className="close"><i className="fa fa-times"></i></Link>
                    <div className="pos-product">
                        <div className="pos-product-img">
                            <div className="img" style={{backgroundImage: 'url(../assets/img/pos/product-1.jpg)'}}></div>
                        </div>
                        <div className="pos-product-info">
                            <div className="title">Grill Chicken Chop</div>
                            <div className="desc">
                                chicken, egg, mushroom, salad
                            </div>
                            <div className="price">$10.99</div>
                            <hr />
                            <div className="option-row">
                                <div className="qty">
                                    <div className="input-group">
                                        <Link to="/pos/customer-order" className="btn btn-default"><i className="fa fa-minus"></i></Link>
                                        <input type="text" className="form-control border-0 text-center" name="" value="1" />
                                        <Link to="/pos/customer-order" className="btn btn-default"><i className="fa fa-plus"></i></Link>
                                    </div>
                                </div>
                            </div>
                            <div className="option-row">
                                <div className="option-title">Size</div>
                                <div className="option-list">
                                    <div className="option">
                                        <input type="radio" id="size3" name="size" className="option-input" checked />
                                        <label className="option-label" for="size3">
                                            <span className="option-text">Small</span>
                                            <span className="option-price">+0.00</span>
                                        </label>
                                    </div>
                                    <div className="option">
                                        <input type="radio" id="size1" name="size" className="option-input" />
                                        <label className="option-label" for="size1">
                                            <span className="option-text">Large</span>
                                            <span className="option-price">+3.00</span>
                                        </label>
                                    </div>
                                    <div className="option">
                                        <input type="radio" id="size2" name="size" className="option-input" />
                                        <label className="option-label" for="size2">
                                            <span className="option-text">Medium</span>
                                            <span className="option-price">+1.50</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="option-row">
                                <div className="option-title">Add On</div>
                                <div className="option-list">
                                    <div className="option">
                                        <input type="checkbox" name="addon[sos]" value="true" className="option-input" id="addon1" />
                                        <label className="option-label" for="addon1">
                                            <span className="option-text">More BBQ sos</span>
                                            <span className="option-price">+0.00</span>
                                        </label>
                                    </div>
                                    <div className="option">
                                        <input type="checkbox" name="addon[ff]" value="true" className="option-input" id="addon2" />
                                        <label className="option-label" for="addon2">
                                            <span className="option-text">Extra french fries</span>
                                            <span className="option-price">+1.00</span>
                                        </label>
                                    </div>
                                    <div className="option">
                                        <input type="checkbox" name="addon[ms]" value="true" className="option-input" id="addon3" />
                                        <label className="option-label" for="addon3">
                                            <span className="option-text">Mushroom soup</span>
                                            <span className="option-price">+3.50</span>
                                        </label>
                                    </div>
                                    <div className="option">
                                        <input type="checkbox" name="addon[ms]" value="true" className="option-input" id="addon4" />
                                        <label className="option-label" for="addon4">
                                            <span className="option-text">Lemon Juice (set)</span>
                                            <span className="option-price">+2.50</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div className="btn-row">
                                <Link to="/pos/customer-order" className="btn btn-default" data-dismiss="modal">Cancel</Link>
                                <Link to="/pos/customer-order" className="btn btn-success">Add to cart <i className="fa fa-plus fa-fw ml-2"></i></Link>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {/* <AddProduct getProductArray={getProductArray}/> */}
        </div>);
      }

