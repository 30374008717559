import { Alert, Button } from 'bootstrap';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { getprodByID, uploadByID } from '../../slices/prodSlice.js';
import { config } from '../../config/config.js';
import { useState } from 'react';
// import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';
import { Container, Modal, ModalBody } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DeleteImg } from '../../slices/prodSlice.js';
import { failureAlert, successAlert } from '../../slices/alertSlice.js';
import { authHeader } from '../../helpers/authHeader.js';







const ProductInfo = (props) => {
	const alert = useSelector(state => state.alert);
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();
	const product = useSelector(state => state.product);
	
	const prod = useSelector(state => state.prod.data);
	const prodState = useSelector(state => state.prod);
	const [file, setfile] = useState();
	const [modalPosItem, setmodalPosItem] = useState(false);
	const [posMobileSidebarToggled, setposMobileSidebarToggled] = useState(false);
	const [IconStyleMaintrue, setIconStyleMaintrue] = useState({fontSize: "25px",
	color: "black"})
	const [IconStyleMainfalse, setIconStyleMainfalse] = useState({fontSize: "15px",
	color: "blue"})
	const urlId = new URLSearchParams(location.search).get('id');
	//  const uuidimg = prod.fotos.uuid;
    console.log("urlid" ,urlId);

	// const id = location.state?.id;

	// console.log("iddddddd", prod);


	if (!urlId) history.push('/product/ProductPage');
	useEffect(() => {
		// if prodSlice is empty OR if uuid aus prodSlice is different than urlId dispatch new request to api
		if ((!prod && !prodState.status && !prodState.error) || (prod && prod.uuid != urlId)) dispatch(getprodByID(urlId));
	}, [prod]);

	const addfoto = () => {
		dispatch(uploadByID({ uuid: urlId, file }));

	}


	const deleteImage = (imguuid) => {
		dispatch(DeleteImg({ uuid: urlId, imguuid }))

	}

	// const selector = useSelector(prodSlice);
	// console.log('id: '+id);
	// console.log('urlID: ' + urlId);
	// console.log("urlif", urlId);
	// console.log("file", file);
	// console.log(location.state);


	const togglePosMobileSidebar = () => {
		setposMobileSidebarToggled(!posMobileSidebarToggled);
	}
	const toggleModal = (name) => {
		switch (name) {
			case 'modalPosItem':
				setmodalPosItem(!modalPosItem);
				break;
			default:
				break;
		}
	}



	// const handleChange = () => {

	// };




	function changeMainImage(urlID1, imguuid1) {
		console.log("urlid", urlID1);
		console.log("urlid", imguuid1)

		const requestOptions = {

			method: 'PATCH',
			headers: authHeader(),
			body: JSON.stringify({
				productUuid: urlID1,
				imageUuid: imguuid1,

			})
		}

		return fetch(`${config.apiUrl}/Products/AssignMainImage?productUuid=${encodeURIComponent(urlID1)}&imageUuid=${encodeURIComponent(imguuid1)}`,
			requestOptions).then(res => {

				if (!res.ok) { dispatch(failureAlert(res.statusText)) }
				else { dispatch(successAlert(`Set Main Image`)) }; return (res.json()),

				dispatch(getprodByID(urlId));

				

			})
			
	}


	


	   useEffect(() => {

		setIconStyleMaintrue({
			fontSize: "28px",
			color: "#ff5b57",
			
		});
		setIconStyleMainfalse({
			fontSize: "25px",
			color: "#348fe2"
		})

		
	}, []);




	return (



		// 	<div>
		// 		<ol className="breadcrumb float-xl-end">
		// 			<li className="breadcrumb-item"><Link to="/page-option/blank">Home</Link></li>
		// 			<li className="breadcrumb-item"><Link to="/page-option/blank">Page Options</Link></li>
		// 			<li className="breadcrumb-item active">Produkt Details</li>
		// 		</ol>
		// 		<h1 className="page-header">Blank Page <small>header small text goes here...</small></h1>
		// 		<Panel>
		// 			<PanelHeader>Product ID: {urlId}</PanelHeader>
		// 			<PanelBody>
		// 			{prod && <>{prod.photos.map(photo => <img src={`${config.webUrl}/${photo.url}`} width={50}/>)}




		// 			</>}
		//       {/* <Alert color="primary">Message: {alert.message}</Alert> */}
		// 	        {/* <h1>Prodinfo...</h1> */}


		// 			</PanelBody>
		// 		</Panel>

		// 		<input type="file" className="form-control" placeholder="" value= {setfile} onChange={(e) => {
		//     if (e.target.files && e.target.files.length==1) setfile(e.target.files[0]);
		//     else if (file) setfile(null);
		//   }} ></input>
		//   <br/>
		//   <button onClick={addfoto}>AddImage</button>
		// 	</div>)}

		<Panel>
			<PanelHeader noButton={true}>
				<div className="d-flex align-items-center">

					<h2 className="page-header">{prod?.name} <small>{prod?.descripton}</small></h2>

					{/* <ol className="page-header" className="breadcrumb float-xl-end">
					<li className="page-header"className="breadcrumb-item"><Link to="/"></Link></li>
					<li className="breadcrumb-item"><Link to="/product/Product">Product List</Link></li>
					<li className="breadcrumb-item active"></li>
				</ol> */}
				</div>
			</PanelHeader>
			<PanelBody>
			{/* className="breadcrumb float-xl-end" */}
				<div>
					
						{/* <li className="page-header" className="breadcrumb-item"><Link to="/"></Link></li> */}
						 <Link to="/product/Product"  >		<div className="mb-3 d-flex align-items-center"><i className="icon-home h3 d-block"></i><div className="ms-3">ProductList</div></div></Link>
						{/* <li className="breadcrumb-item active"></li> */}
					
					<h1 className="page-header"> <small>{prod?.descripton}</small></h1>


					<div className={'pos pos-customer ' + (posMobileSidebarToggled ? 'pos-mobile-sidebar-toggled' : '')} id="pos-customer">
						<div className="pos-menu">

							<div className="logo">
								<Link to="/">
									<div className="logo-img"><img alt="" src="../assets/img/pos/logo.svg" /></div>
									<div className="logo-text">Product & Details</div>
								</Link>
							</div>
							<div className="nav-container">
								<PerfectScrollbar className="height-full" options={{ suppressScrollX: true }}>

									<ul className="nav nav-tabs">

										<li className="nav-item">{""}

											<Link to="/Products/ProductInfo" className="nav-link active" data-filter="all">
												{/* {prod && prod.photos?.map(yy => (prod.name))} */}
												{prod && prod.name}
											</Link>
										</li>
										<li className="nav-item">{""}

											<Link to="/Products/ProductInfo" className="nav-link active" data-filter="all"><h6>Quantity</h6>
												{/* {prod && prod.photos?.map(yy => (prod.quantity))} */}
												{prod && prod.quantity}
											</Link>


										</li>


									</ul>
								</PerfectScrollbar>
							</div>
						</div>

						<div className="pos-content">
							<PerfectScrollbar className="pos-content-container" options={{ suppressScrollX: true }}>
								<div className="product-row">
									{prod && prod.photos?.map(photo =>


										<div className="product-container" data-type="drinks">



											<div className="img" style={{ height: 250 }}>
												<img src={`${config.webUrl}/${photo.url}`} width={200} />
											</div>
											<div className="text">
												<div className="title">{prod.name}</div>
												<div className="desc">{photo.id}</div>
												<br/>

												{/* className="ion ion-md-checkmark-circle"  */}

												<button style={{ border: "none", backgroundColor: "initial" }} onClick={() => { deleteImage(photo.uuid) }}><i ></i>  <i className="icon-trash h3 d-block , text-danger" style={{ fontSize: "20px"}} ></i></button>
												<button style={{ border: "none", backgroundColor: "initial" }} onClick={() => changeMainImage(prod.uuid, photo.uuid)} title="Set main foto" >
													<i style={photo.main === true ? IconStyleMaintrue : IconStyleMainfalse}  className="btn btn-success" className="ion ion-md-checkmark-circle"></i>
													<Link to="/ui/buttons" className=" btn-icon btn-circle me-1"></Link></button>
												{/* <input className="form-check-input" type="checkbox" defaultValue="" id="flexCheckChecked" checked /> */}
												<div className="price">{ } { }</div>
											</div>

											{/* <button onClick={()=>confirmDelete(product.uuid)} >Delete</button> */}
										</div>)}

								</div>
							</PerfectScrollbar>
						</div>

						<div className="pos-sidebar" id="pos-sidebar">

							<input type="file" className="form-control" onChange={(e) => {
								if (e.target.files && e.target.files.length == 1) setfile(e.target.files[0]);
								else if (file) setfile(null);
							}} ></input> <br />
							{/* <Link to={`/product/ProductInfo?id=${urlId}`} onClick={addfoto} className="btn btn-success"><i className="fa fa-check fa-fw"></i> Add Neu foto</Link> */}
							<button type="button" className="btn btn-success me-1 mb-1" className="btn btn-sm btn-primary btn-me" onClick={addfoto}>Add Neu foto</button>
						</div>
					</div>

					<Link to="/pos/customer-order" className="pos-mobile-sidebar-toggler" onClick={() => togglePosMobileSidebar()}>
						<svg viewBox="0 0 16 16" className="img" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
							<path fillRule="evenodd" d="M14 5H2v9a1 1 0 0 0 1 1h10a1 1 0 0 0 1-1V5zM1 4v10a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V4H1z" />
							<path d="M8 1.5A2.5 2.5 0 0 0 5.5 4h-1a3.5 3.5 0 1 1 7 0h-1A2.5 2.5 0 0 0 8 1.5z" />
						</svg>
						<span className="badge">5</span>
					</Link>



				</div>

			</PanelBody>
		</Panel>)
}

export default ProductInfo;

//test