import React, { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

const ExtraError = () => {
	// static contextType = AppSettings;
	// componentDidMount() {
	// 	this.context.handleSetAppSidebarNone(true);
	// 	this.context.handleSetAppHeaderNone(true);
	// 	this.context.handleSetAppContentClass('p-0');
	// }

	// componentWillUnmount() {
	// 	this.context.handleSetAppSidebarNone(false);
	// 	this.context.handleSetAppHeaderNone(false);
	// 	this.context.handleSetAppContentClass('');
	// }

	const context = useContext(AppSettings);
	const user = useSelector(state => state.user)
	
	useEffect(() => {
		if (!user.loggedIn) {
			context.handleSetAppSidebarNone(true);
			context.handleSetAppHeaderNone(true);
			context.handleSetAppContentClass('p-0');
		}
		return () => {
			context.handleSetAppSidebarNone(false);
			context.handleSetAppHeaderNone(false);
			context.handleSetAppContentClass('');
		}
	}, [])
  
	return (
		<div className="error">
			<div className="error-code">404</div>
			<div className="error-content">
				<div className="error-message">We couldn't find it...</div>
				<div className="error-desc mb-4">
					The page you're looking for doesn't exist.
				</div>
				<div>
					<Link to="/dashboard/v3" className="btn btn-success px-3">Go Home</Link>
				</div>
			</div>
		</div>
	)
}

export default ExtraError;