import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { logout } from '../../../slices/userSlice';

const DropdownProfile = (props) => {
	const dispatch = useDispatch();

	const user = useSelector(state => state.user);

	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = () => {
		setDropdownOpen(!dropdownOpen);
	}

	return (
		<Dropdown isOpen={dropdownOpen} toggle={toggle} className="navbar-item navbar-user dropdown" tag="div">
			<DropdownToggle tag="a" className="navbar-link dropdown-toggle d-flex align-items-center">
				{/* <img src="../assets/img/user/user-13.jpg" alt="" />  */}
				<i className="icon-user"></i>
				<span>
					<span className="d-none d-md-inline">{user.username}</span>
					<b className="caret"></b>
				</span>
			</DropdownToggle>
			<DropdownMenu className="dropdown-menu dropdown-menu-end" right tag="div">
				<DropdownItem>Edit Profile</DropdownItem>
				<DropdownItem><span className="badge bg-danger float-end rounded-pill">2</span> Inbox</DropdownItem>
				<DropdownItem>Calendar</DropdownItem>
				<DropdownItem>Setting</DropdownItem>
				<div className="dropdown-divider"></div>
				<DropdownItem onClick={() => dispatch(logout())}>Log Out</DropdownItem>
			</DropdownMenu>
		</Dropdown>
	);
};

export default DropdownProfile;
