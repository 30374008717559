import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


// import { authService } from '../services/authService';
import { authHeader } from '../helpers/authHeader';
import { uploadHeader } from '../helpers/authHeader';
import { config } from '../config/config';
import { logout } from './userSlice';


export const CatStatus = {
  loading: 'CatLOADING',
  success: 'CatSUCCESS',
  failure: 'CatFAILURE',
};




export const getcategoryListe = createAsyncThunk(
  '/Categories/GetCategoriesWithProducts',
  async () => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
    };

    return fetch(`${config.apiUrl}/Categories/GetCategoriesWithProducts`, requestOptions).then(res => res.json());
  }
);


const initialState = {};
// const loadedState = {
//   loaded: true
// }

export const CategorySlice = createSlice({
  name: 'Category',
  initialState,
  reducers: {
    reset: () => {
      return initialState
    },
  },

  extraReducers: {

    [getcategoryListe.pending]: (state, action) => {
      return {
        status: CatStatus.loading
      }


    },

    [getcategoryListe.fulfilled]: (state, action) => {
      state.status = CatStatus.success;
      state.data = action.payload;

    },

    [getcategoryListe.rejected]: (state, action) => {
      return {
        status: CatStatus.failure
      }
    },
  }
})

export const { reset } = CategorySlice.actions;

export default CategorySlice.reducer;

