import React, { useState, useEffect } from 'react';
import { authHeader, config, uploadHeader } from '../../helpers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { failureAlert, successAlert } from '../../slices/alertSlice';
import { getcategoryListe } from '../../slices/CategorySlice';
import UploadPhoto from './uploadPhoto';



import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBCardOverlay, MDBCardFooter } from 'mdb-react-ui-kit';

export default function LinkShowPhotos() {
    const dispatch = useDispatch();
    const history = useHistory();
    console.log("history ", history);
    const location = useLocation();

    const { categoryPhotos, categoryuuid, to, categorieName } = location.state;
    console.log("categoryPhotos ", categoryPhotos);


    const [buttomStyle, setbuttomStyle] = useState({})
    const [IconStyleMaintrue, setIconStyleMaintrue] = useState({})
    const [IconStyleMainfalse, setIconStyleMainfalse] = useState({})

    useEffect(() => {
        setbuttomStyle({
            position: 'absolute',
            bottom: '3%',
            left: '1%',
            border: "none",
            backgroundColor: "initial"
        });
        setIconStyleMaintrue({
            fontSize: "35px",
            color: "green",
        });
        setIconStyleMainfalse({
            fontSize: "20px",
            color: "green"
        })

    }, []);


    ////

    function changeMainImage(categroieUUid, ImageUid) {


        const requestOptions = {

            method: 'PATCH',
            headers: authHeader(),
            body: JSON.stringify({

                categoryUuid: categroieUUid,
                imageUuid: ImageUid,

            })
        };

        return fetch(`${config.apiUrl}/Categories/AssignMainImage?categoryUuid=${encodeURIComponent(categroieUUid)}&imageUuid=${encodeURIComponent(ImageUid)}`,
            requestOptions).then(res => {

                if (!res.ok) { dispatch(failureAlert(res.statusText)) }
                else { dispatch(successAlert(`Set Main Image`)) }; return (res.json())
            })
            .then(() => dispatch(getcategoryListe()))
            .then(() => { history.push(to) })

        // display()


    }
    ////
    function display() {

        const categoriePhotosDispaly = categoryPhotos.map((show) => {
            return <React.Fragment key={Math.random(10000) * 10}>
                <MDBCol  >
                    <MDBCard >
                        <div style={{ position: 'relative' }}>
                            <MDBCardImage overlay
                                src={(`${config.webUrl}/${show.url}`)}
                                alt='...'
                                // width="80"
                                height="250"
                                position='top'
                            />
                            <MDBCardOverlay  >

                                <button onClick={() => changeMainImage(categoryuuid, show.uuid)} title="Set Categorie Image" style={buttomStyle}>
                                    <i style={show.main === true ? IconStyleMaintrue : IconStyleMainfalse} className="ion ion-md-checkmark-circle"  >
                                    </i>
                                </button>

                            </MDBCardOverlay>
                        </div>
                        <MDBCardBody>
                            <MDBCardTitle>{show.description}</MDBCardTitle>
                            <MDBCardText>
                                Category:{categorieName}
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>

                </MDBCol>

            </React.Fragment >
        })



        return categoriePhotosDispaly;

    }

    return <React.Fragment>
        <UploadPhoto/>

        <MDBRow className='row-cols-1 row-cols-md-5 g-4'>
            {display()}
        </MDBRow>

    </React.Fragment>
}

