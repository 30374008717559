import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


// import { authService } from '../services/authService';
import { authHeader } from '../helpers/authHeader';
import { uploadHeader }from '../helpers/authHeader' ;
import { config } from '../config/config';
import { DeleteImg, Deleteprod } from './prodSlice';
import { logout } from './userSlice';


export const prodlisteStatus = {
  loading: 'PRODUCTBYIDLOADING',
  success: 'PRODUCTBYIDSUCCESS',
  failure: 'PRODUCTBYIDFAILURE',
};




export const getProductListe = createAsyncThunk(
  '/Products/GetProducts',
  async () => {

    const requestOptions = {
      method:'GET',
      headers: authHeader(),
      // body: JSON.stringify({
      //   uuid: encodeURIComponent(uuid)
      // })
    };
  
    return fetch(`${config.apiUrl}/Products/GetProducts`, requestOptions)
    .then(res  =>  res.json()
    
    )

    
  }
)
  

   const initialState = {};
  // const loadedState = {
  //   loaded: true
  // }

export const ProduktListeSlice = createSlice({
  name: 'produktliste',
  initialState,
  reducers:{
    reset : () => {
      return initialState
    },
  },

  extraReducers: {
    
    [getProductListe.pending]: (state,action) => {
        state.status =prodlisteStatus.loading;
    
      
    },

    [getProductListe.fulfilled]: (state,action) => {
      state.status=prodlisteStatus.success;
      state.data=action.payload;
        
    },

    [getProductListe.rejected]:(state,action)=>{
      state.status=prodlisteStatus.failure;
    },

    [DeleteImg.fulfilled]:(state,action)=>{
      return initialState;
    },

    [Deleteprod.fulfilled]:(state,action)=>{
      return initialState;
    },
    [logout.pending]:(state,action)=>{
      return initialState;
    },

  
   
  },


  
})
         
  export const { reset } = ProduktListeSlice.actions;

  export default ProduktListeSlice.reducer;