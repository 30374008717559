import React, { useState, useEffect } from 'react';
import { authHeader, config } from '../../helpers';
import Deletetegories from './deleteategories';
import AddCategories from './addcategories';
import UpdateCategories from './updatecategories'
import UploadPhoto from './uploadPhoto';
import Displayphoto from './displayPhoto';
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

// design

import { MDBCard, MDBCardImage, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBCardOverlay, MDBCardFooter } from 'mdb-react-ui-kit';


import { Panel, PanelHeader, PanelBody } from '../../components/panel/panel.jsx';
import { failureAlert } from '../../slices/alertSlice';
import { useDispatch, useSelector } from 'react-redux';
import { getcategoryListe } from '../../slices/CategorySlice';

export default function Categories() {


    const location = useLocation();
    const url = location.pathname;


    const dispatch = useDispatch();
    const categories = useSelector(state => state.Category);


    const [mainPhotos, setmainPhotos] = useState({});

    //Get function
    useEffect(() => {

        if (!categories.data && !categories.status) dispatch(getcategoryListe());

    }, [categories.data]);

    useEffect(() => {

        const photos = {};
        categories.data?.forEach(category => {
            photos[category.uuid] = category.photos.find(photo => photo.main === true);
        });
console.log("photos",photos);
        setmainPhotos(photos);
    }, [categories.data]);

    console.log(categories);
    let displayCategory = categories.data?.map((category, index) => {

        return (<React.Fragment key={Math.random(10000) * 10}>

            <MDBCol>
                <MDBCard>
                    <div style={{ position: 'relative' }}>
                        {/* Display Photo */}
                        <Displayphoto mainPhoto={mainPhotos[category.uuid]} categoriesPhoto={category.photos} />
                        {/* ////// */}

                        {/* To Add photo */}
                        <MDBCardOverlay  >
                            <UploadPhoto categorieName={category.name} categoryuuid={category.uuid} categoryId={category.id} />
                        </MDBCardOverlay>
                        {/* ////// */}

                    </div>
                    <MDBCardBody>
                        {/* To update Categorie Name */}
                        <MDBCardTitle>
                            <UpdateCategories index={index} categorieName={category.name}
                                oldCategoriesList={categories.data} categoryuuid={category.uuid} />
                        </MDBCardTitle>
                        {/* ////// */}

                        {/* Number of Products with link to products */}
                        <MDBCardText>
                            <span style={{ fontWeight: "900" }}> Numbers of Products :
                                <Link to={`/product/Product?categoryUuid=${encodeURIComponent(category.uuid)}#"dory"`}>

                                    {category.products.length}

                                </Link>
                            </span>
                        </MDBCardText>
                        {/* ////// */}

                        {/* Display Picture Name */}
                        <MDBCardText>
                            <span style={{ fontWeight: "900" }}> Picture Name :
                                {mainPhotos[category.uuid]?.description.split('.').slice(0, -1).join('.')}
                            </span>
                        </MDBCardText>

                    </MDBCardBody>
                    {/* ////// */}

                    <div className={"circle-btn-group"}>
                        <Link
                            className={"btn btn-success btn-icon  btn-circle btn-lg"}
                            role={"button"}
                            to={{
                                pathname: `/categories/linkShowPhotos`,
                                state: {
                                    categoryPhotos: category.photos,
                                    categoryuuid: category.uuid,
                                    categorieName: category.name,
                                    to: url,
                                }
                            }}>
                            <i className="far fa-images"/>

                        </Link>
                        <Deletetegories categorieName={category.name} categoryuuid={category.uuid}/>
                    </div>
                </MDBCard>

            </MDBCol>

        </React.Fragment>

        )
    })


    return <div className="pageWork" ><h2>Categories</h2>

        <div className="col-xl-6">
            <div className="form-group row">

                {/* to call function add  */}
                <div className="col-lg-8" >
                    <AddCategories oldCategoriesList={categories.data} />
                </div>
            </div>

        </div>

        {/* display categories */}
        <Panel>
            <PanelHeader>Categories List</PanelHeader>
            <PanelBody>

                <MDBRow className='row-cols-1 row-cols-md-4 g-4'>
                    {displayCategory}
                </MDBRow>

            </PanelBody>
        </Panel>
    </div>

}

