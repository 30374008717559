import React, { useState, useEffect, createContext } from 'react';
import { authHeader, config, uploadHeader } from '../../helpers';
import InputMask from 'react-input-mask';
import { failureAlert, successAlert } from '../../slices/alertSlice';
import { useDispatch } from 'react-redux';
import { getcategoryListe } from '../../slices/CategorySlice';

export const AddContext = createContext()

export default function UploadPhoto(props) {
    const dispatch = useDispatch();
    const { categorieName, categoryuuid, categoryId } = props
    
    const [addPhoto, setaddPhoto] = useState()

    // const [photo, setphoto] = useState("")

    function uploadPhoto(photo) {
        console.log("photo ", photo.name);
        const fs = new FormData()
        fs.append('uploadedFile', photo);

        const requestOptions = {
            method: 'POST',
            headers: uploadHeader(),
            body: fs
        };

        return fetch(`${config.apiUrl}/Categories/AddImage?categoryId=${encodeURIComponent(categoryuuid)}

                &description=${encodeURIComponent(photo.name)}`, requestOptions)

            .then(res => {
                if (!res.ok) { dispatch(failureAlert(res.statusText)) }
                else { dispatch(successAlert(`Successfully added Image `)) }
                return (res.json())
            })

            .then(res => { dispatch(getcategoryListe()) })
    }

    //toggle

    const [isEdit, setisEdit] = useState({ edit: true })
    let { edit } = isEdit

    function toggle() {
        setisEdit({
            edit: !edit
        })
    }


    //
    function _uploadPhotos() {

        return (<React.Fragment>

            <InputMask id="firstimg" style={{ display: "none", visibility: "none" }} type="file" onChange={(e) => {
                uploadPhoto(e.target.files[0]);

            }} />
            {/* <button title="Save" style={{ border: "none", backgroundColor: "initial" }} onClick={() => { uploadPhoto() }} ><i className="ion ion-md-save " style={{ fontSize: "20px", color: "blue" }} /></button>
            <button title="Close" style={{ border: "none", backgroundColor: "initial" }} onClick={() => _exitWithOutAdd()} ><i className="ion ion-md-close-circle" style={{ fontSize: "20px", color: "red" }} /></button> */}

        </React.Fragment>
        )
    }


    return <React.Fragment>


        <button title="Add Image" className={"btn btn-success btn-icon btn-circle btn-lg"}
            style={{ left: '1%', bottom: '3%', position: 'absolute' }}

            onClick={() => {
                toggle()
            }}>
            <label className={"label-btn"} htmlFor="firstimg">
                <i className="fas fa-plus" />
            </label>
        </button>
        {edit ? "" : _uploadPhotos()}
    </React.Fragment>

}