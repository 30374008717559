import React, { useState } from 'react';


import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Highlight from "react-highlight";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { warningAlert } from "../../slices/alertSlice";
import { confirmAlert } from "../../slices/alertSlice";
import { successAlert } from "../../slices/alertSlice";
import { FormGroup, Input, Label } from "reactstrap";
import { authHeader, config, uploadHeader } from "../../helpers";
import { useEffect } from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { getcategoryListe } from "../../slices/CategorySlice";
import { getProductListe } from "../../slices/ProduktListeSlice";




const emptyCategory = {
    uuid: "",
};


export const EditProductForm = (props) => {

   
    const Category = useSelector((state) => state.Category.data);
    const CategoryState = useSelector((state) => state.Category);
    const [showmodal, setshowmodal] = useState(false);
    // const [showForm, setshowForm] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    // const [file, setfile] = useState();
    const [ProductId, setProductId] = useState(props.data.uuid);
    const [ProductName, setProductName] = useState(props.data.name);
    const [ProductPreis, setProductPreis] = useState(props.data.netPrice);
    const [categoryID, setcategoryID] = useState(props.data.categoriesUids);
    const [selectedCategory, setselectedCategory] = useState(emptyCategory);
    const [categories, setcategories] = useState(props.data.categoriesUids.map((uuid) => Category && Category.find((cat)=> cat.uuid == uuid)));
    const produktliste = useSelector((state) => state.produktliste.data);
    const productListeState = useSelector((state) => state.produktliste);
    // const [filterProducts, setfilterProducts] = useState([]);
    // const [filterString, setfilterString] = useState("");
    const history = useHistory();
    // const [posMobileSidebarToggled, setposMobileSidebarToggled] = useState(false);
    // const [modalPosItem, setmodalPosItem] = useState(false);

    useEffect(() => {
        if (!Category && !CategoryState.status && !CategoryState.error) dispatch(getcategoryListe());
           
      }, [Category]);

    const toggleModal = () => {

        setshowmodal(!showmodal);
       
      
                

    }


  



    const resetInputs = () => {
        setProductId(props.data.uuid);
        setProductName(props.data.name);
        setProductPreis(props.data.netPrice);
        setcategoryID(props.data.categoriesUids);
        setcategories(props.data.categoriesUids.map((uuid) => Category && Category.find((cat)=> cat.uuid == uuid)));

     

    };

   

    const edithandler = () => {   const requestOptions = {
        method: "POST",
        headers: authHeader(),
        body: JSON.stringify({
          uuid: encodeURIComponent(ProductId),
          designerId: user.uuid,
          name: ProductName,
          netPrice: 0,
          currency: "",
          netPrice: ProductPreis,
          quantity: 0,
          photos: [],
          categoriesUids: categories?.map(elemntt => elemntt.uuid),
        }),
      };
  
      fetch(`${config.apiUrl}/Products/UpdateProduct`, requestOptions).then(
        (response) => {
          dispatch(getProductListe());
          dispatch(successAlert("Produkte edited successfully"));
                      
        }
      );
    }

    useEffect( () => {
        // edithandler ();
        dispatch(getProductListe());
       
      }, []);

    if (!showmodal) {
        return (
            
            <button  style={{ border: "none" , backgroundColor: "initial" }} 
            onClick={()=> {
                toggleModal();
                resetInputs();
            }}>
             <i className="icon-pencil h3 d-block , text-success"  ></i> 
            </button>

        );
    }

     return (<Modal isOpen={showmodal} toggle={() => toggleModal()} >
        <ModalHeader toggle={() => toggleModal()} close={<button className="btn-close" onClick={() => toggleModal()}></button>}>
            <strong>Update Product</strong><br />
        </ModalHeader >

        <ModalBody>
            <div>



                <form className="form-horizontal form-bordered">
                    <div className="form-group row">
                        <label className="col-lg-4 col-form-label">Produkt ID</label>
                        <div className="col-lg-8">
                            <InputMask
                                value={ProductId}
                                onChange={(e) => setProductId(e.target.value)}
                                className="form-control"
                                type="text"
                                placeholder="Produkt ID"
                                className="btn btn-default disabled me-1 mb-1"
                            ></InputMask>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-4 col-form-label">Produkt Name</label>
                        <div className="col-lg-8">
                            <InputMask
                                value={ProductName}
                                onChange={(e) => setProductName(e.target.value)}
                                mask=""
                                type="text"
                                className="form-control"
                                placeholder=""
                                className="btn btn-default active me-1 mb-1"
                            ></InputMask>
                        </div>
                    </div>
                    <div className="form-group row">
                        <label className="col-lg-4 col-form-label">Produkt Preis</label>
                        <div className="col-lg-8">
                            <InputMask
                                value={ProductPreis}
                                onChange={(e) => setProductPreis(e.target.value)}
                                type="number"
                                className="form-control"
                                placeholder=""
                                className="btn btn-default active me-1 mb-1"
                            ></InputMask>
                        </div>
                    </div>
                  
                    <div className="form-group row">
                        <label className="col-lg-4 col-form-label">
                            Add Category
                        </label>
                        <div className="col-lg-8">
                            <Input
                                type="select"
                                className="form-control"
                                id="exampleSelect"
                                value={selectedCategory.uuid}
                                onChange={(e) => {
                                    const c = Category.find(
                                        (category) => category.uuid == e.target.value
                                    );
                                    setselectedCategory(c || emptyCategory);
                                }}
                            >
                                <option value={""}>Select a Category</option>
                                {Category.filter(
                                    (category) =>
                                        !categories.find(
                                            (category2) => category.uuid == category2.uuid
                                        )
                                ).map((category, index) => (
                                    <option value={category.uuid}>{category.name}</option>
                                ))}
                            </Input>
                        </div>
                      
                    </div>

                    <div className="form-group row">
                        <div className="col-lg-8">
                            <div className="table-responsive">
                                <table className="table table-hover mb-0 text-inverse">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>CategoryName</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td></td>
                                            <td>
                                                {" "}
                                                {categories && categories.map((category) => (
                                                    <>
                                                        {" "}
                                                        {category.name}{" "}
                                                        <button style={{ border: "none" , backgroundColor: "initial" }} 
                                                            // className="btn btn-sm btn-white w-60px"
                                                            onClick={() => {
                                                                setcategories(
                                                                    categories.filter(
                                                                        (category2) =>
                                                                            category.uuid != category2.uuid
                                                                    )
                                                                );
                                                            }}
                                                            
                                                        >
                                                             <i className="icon-trash text-danger"  ></i>
                                                        </button>
                                                        <br />
                                                    </>
                                                ))}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="hljs-wrapper"></div>
                        </div>
                    </div>
                </form>

                <button
                    onClick={() => setcategories([...categories, selectedCategory])}
                    disabled={!selectedCategory.uuid}
                    className="btn btn-default active me-1 mb-1"
                >
                    add Category
                </button>


            </div>

            <br />

            <FormGroup>

                <ul>
                    <br />

                    {categories && categories
                        .filter((x) => x.categoriesUids == categoryID)
                        .map((cat) => (
                            <li key={cat.categoriesUids}>
                                {" "}
                                {cat.name}{" "}

                            </li>
                        ))}
                </ul>

            </FormGroup>
          


        </ModalBody>

        <ModalFooter>
            <button className="btn btn-white" onClick={() => toggleModal()}>Close</button>
            <button onClick={edithandler} className="btn btn-success" className="btn btn-sm btn-primary btn-me">Save </button>
        </ModalFooter>
    </Modal>)

           

}
























