import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AppSettings } from './../../config/app-settings.js';

const SidebarProfile = (props) => {
	const user = useSelector(state => state.user);
	const [profileActive, setProfileActive] = useState(0);

	const handleProfileExpand = (e) => {
		e.preventDefault();
		setProfileActive(!profileActive);
	}
	return (
		<AppSettings.Consumer>
			{({appSidebarMinify}) => (
				<div>
					<div className={"menu-profile " + (profileActive ? "expand " : "")}>
						<Link to="/" onClick={handleProfileExpand} className="menu-profile-link">
							<div className="menu-profile-cover with-shadow"></div>
							<div className="menu-profile-image">
								<img src="../assets/img/user/user-13.jpg" alt="" />
							</div>
							<div className="menu-profile-info">
								<div className="d-flex align-items-center">
									<div className="flex-grow-1">
										{user.username || 'User' }
									</div>
									<div className="menu-caret ms-auto"></div>
								</div>
								<small>{user.userType || 'Guest'}</small>
							</div>
						</Link>
					</div>
					<div className={"collapse " + (profileActive && !appSidebarMinify ? "show " : "")}>
						<div className="menu-item pt-5px">
							<Link to="/" className="menu-link">
								<div className="menu-icon"><i className="fa fa-cog"></i></div>
								<div className="menu-text">Settings</div>
							</Link>
						</div>
						<div className="menu-item">
							<Link to="/" className="menu-link">
								<div className="menu-icon"><i className="fa fa-pencil-alt"></i></div>
								<div className="menu-text"> Send Feedback</div>
							</Link>
						</div>
						<div className="menu-item pb-5px">
							<Link to="/" className="menu-link">
								<div className="menu-icon"><i className="fa fa-question-circle"></i></div>
								<div className="menu-text"> Helps</div>
							</Link>
						</div>
						<div className="menu-divider m-0"></div>
					</div>
				</div>
			)}
		</AppSettings.Consumer>
	)
}

export default SidebarProfile;