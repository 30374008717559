
import React from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useDispatch, useSelector } from 'react-redux';
import { alertType, dismissAlert } from '../../slices/alertSlice';

function AlertNotification(props) {
  const alert = useSelector(state => state.alert);
  const dispatch = useDispatch();

  switch (alert.type) {
    case alertType.confirm:
      return (<SweetAlert showCancel={!alert.hideCancel}
        confirmBtnText="Confirm"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Please Confirm"
        onConfirm={() => {
          if (alert.onConfirm) alert.onConfirm();
          dispatch(dismissAlert());
        }}
        onCancel={() => {
          if (alert.onCancel) alert.onCancel();
          dispatch(dismissAlert());
        }}
      >
        {JSON.stringify(alert.message)}
      </SweetAlert>);
    case alertType.failure:
      return (<SweetAlert danger
        confirmBtnText="Continue"
        confirmBtnBsStyle="danger"
        cancelBtnBsStyle="default"
        title="Error"
        onConfirm={() => dispatch(dismissAlert())}
      >
        {JSON.stringify(alert.message)}
      </SweetAlert>);
    case alertType.info:
      return (<SweetAlert info
        confirmBtnText="Continue"
        confirmBtnBsStyle="info"
        cancelBtnBsStyle="default"
        title="Info"
        onConfirm={() => dispatch(dismissAlert())}
      >
        {JSON.stringify(alert.message)}
      </SweetAlert>);
    case alertType.primary:
      return (<SweetAlert
        confirmBtnText="Continue"
        confirmBtnBsStyle="primary"
        cancelBtnBsStyle="default"
        title="Alert"
        onConfirm={() => dispatch(dismissAlert())}
      >
        {JSON.stringify(alert.message)}
      </SweetAlert>);
    case alertType.success:
      return (<SweetAlert success
        confirmBtnText="Continue"
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="default"
        title="Success"
        onConfirm={() => dispatch(dismissAlert())}
      >
        {JSON.stringify(alert.message)}
      </SweetAlert>);
    case alertType.warning:
      return (<SweetAlert warning
        confirmBtnText="Continue"
        confirmBtnBsStyle="warning"
        cancelBtnBsStyle="default"
        title="Warning"
        onConfirm={() => dispatch(dismissAlert())}
      >
        {JSON.stringify(alert.message)}
      </SweetAlert>);
  
    default:
      return null;
  }
}

export default AlertNotification

