import React, { useState } from 'react';


import SweetAlert from 'react-bootstrap-sweetalert';
import { Modal, ModalHeader, ModalBody, ModalFooter } from'reactstrap';
import Highlight from "react-highlight";
import InputMask from "react-input-mask";
import { useHistory } from "react-router";
import { useDispatch,useSelector } from "react-redux";
import { warningAlert } from "../../slices/alertSlice";
import { confirmAlert } from "../../slices/alertSlice";
import { successAlert } from "../../slices/alertSlice";
import { FormGroup, Input, Label } from "reactstrap";
import { authHeader, config, uploadHeader } from "../../helpers";
import { useEffect } from 'react';
import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import { getProductListe } from '../../slices/ProduktListeSlice';



const emptyCategory = {
  uuid: "",
};


export const AddProductForm = (props) => {

 
    const products = useSelector(state => state.produktliste.data);

    const [showmodal,setshowmodal] =useState(false);
    // const [showForm, setshowForm] = useState(false);
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const [file, setfile] = useState();
    const [ProductId, setProductId] = useState("");
    const [ProductName, setProductName] = useState("");
    const [ProductPreis, setProductPreis] = useState("");
    const [categoryID, setcategoryID] = useState("");
    const [CategoryState, setCategoryState] = useState([]);
    const [selectedCategory, setselectedCategory] = useState(emptyCategory);
    const [categories, setcategories] = useState([]);
    // const [products, setProducts] = useState([]);
    // const [filterProducts, setfilterProducts] = useState([]);
    // const [filterString, setfilterString] = useState("");
    const history = useHistory();
    const [posMobileSidebarToggled, setposMobileSidebarToggled] = useState(false);
    const [modalPosItem, setmodalPosItem] = useState(false);


  // const [modalIsOpen, setmodalIsOpen] = useState(false)
    
  

  const toggleModal = () => {

    setshowmodal(!showmodal);
  }


    
    // React.useEffect(() => {
    //   getProducts().then((res) => {
    //     setProducts(res);
    //     setfilterProducts(res);
    //   });
    // }, []);
  
    // async function getProducts() {
    //   const requestOptions = {
    //     method: "GET",
    //     headers: authHeader(),
    //   };
  
    //   return fetch(`${config.apiUrl}/Products/GetProducts`, requestOptions)
    //     .then((res) => res.json())
    //     .then((res) => {
    //       return res;
    //     });
    // }
  
    async function upload(uuid) {
      if (file) {
        const formData = new FormData();
        formData.append("uploadedFile", file);
        return fetch(
          `${config.apiUrl}/Products/AddImage?productId=${encodeURIComponent(
            uuid
          )}`,
          {
            method: "POST",
            // body should be file
            body: formData,
            // body: JSON.stringify(formData)
            headers: uploadHeader(),
            // headers: authHeader(),
          }
        );
      }
    }
  
    async function ProductHinzu(inputs) {
      const response = await fetch(`${config.apiUrl}/Products/AddNewProduct`, {
        method: "POST",
        body: JSON.stringify(inputs),
        headers: authHeader(),
      });
  
      const data = await response.json();
  
      await upload(data.targetUuid);
      dispatch(getProductListe());

      // getProducts().then(async (res) => {
      //   setProducts(res);
      // });
  
      setProductId("");
      setProductName("");
      setProductPreis("");
      setcategories([]);
  
      dispatch(successAlert("Produkte ist erfolgreisch hinzugefüget"));

      
  
      console.log("error");
    }

  
    // useEffect(() => {
    //   if (filterString) {
    //     setfilterProducts(
    //       products.filter((prod) =>
    //         prod.name.toLowerCase().includes(filterString.toLowerCase())
    //       )
    //     );
  
    //     console.log(filterProducts);
    //   } else {
    //     setfilterProducts(products);
    //   }
    // }, [filterString, products]);
  
    const addhandler = () => {
      // console.log(getProduct);
      //producte.name === ProductName
      // getProduct.includes(ProductName)
      //console.log(getProduct.name);
      //console.log(ProductName);
      // console.log((getProduct.slice(1,2)));
  
      const proname = products.map((prod33) => prod33.name);
      // console.log(proname);
      // console.log(getProduct);
  
      if (!proname.includes(ProductName)) {
        ProductHinzu({
          designerId: user.uuid,
          name: ProductName,
          netPrice: ProductPreis,
          currency: "",
          totalPrice: 0,
          quantity: 0,
          photos: [],
          categoriesUids: categories.map((category) => category.uuid),
        });
  
        setProductId("");
        setProductName("");
        setProductPreis("");
        // setcategories([]);
      } else {
        dispatch(
          warningAlert("Sie dürfen nicht Duplicate Produkte Name angeben")
        );
  
        console.log("error");
      }
    };
  
    React.useEffect(() => {
      const requestOptions = {
        method: "GET",
        headers: authHeader(),
      };
  
      fetch(`${config.apiUrl}/Categories/GetCategoriesList`, requestOptions)
        .then((response) => response.json())
        .then((response) => {
          setCategoryState(response);
          // console.log(response);
        });
    }, []);
  
    // console.log("cattttttttttt", CategoryState);
  
    // const editProduct = () => {
  
    // } ;
  
    // const deletehandler = (uuid) => {
    //   const requestOptions = {
    //     method: "DELETE",
    //     headers: authHeader(),
    //     body: JSON.stringify({
    //       uuid: encodeURIComponent(uuid),
    //     }),
    //   };
    //   fetch(`${config.apiUrl}/Products/DeleteProduct`, requestOptions).then(
    //     (response) => {
    //       console.log(response);
    //       getProducts().then((res) => setProducts(res));
    //     }
    //   );
    // };
  
    // const confirmDelete = (uuid) => {
    //   dispatch(
    //     confirmAlert({
    //       message: "Deleting this Product cannot be undone.",
    //       confirm: () => {
    //         deletehandler(uuid);
    //       },
    //     })
    //   );
    // };


   if (!showmodal) { return (
  

  <button onClick={toggleModal} className="btn btn-sm btn-success btn-lg"
  // className="btn btn-default active me-1 mb-1"
  // className="btn btn-primary btn-lg"
  >AddProdukt</button>
  
  );
   }

  else return (<Modal  isOpen={setshowmodal} toggle={() => toggleModal('showmodal')} >
    <ModalHeader toggle={() => toggleModal('showmodal')} close={<button className="btn-close" onClick={() => toggleModal('close')}></button>}>
    <strong>AddProduct.</strong><br /> 
    </ModalHeader >
    
    <ModalBody>
    <div>
   


    
            <form className="form-horizontal form-bordered">
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">Produkt ID</label>
                <div className="col-lg-8">
                  <InputMask
                    value={ProductId}
                    onChange={(e) => setProductId(e.target.value)}
                    className="form-control"
                    type="text"
                    placeholder="Produkt ID"
                    className="btn btn-default disabled me-1 mb-1"
                  ></InputMask>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">Produkt Name</label>
                <div className="col-lg-8">
                  <InputMask
                    value={ProductName}
                    onChange={(e) => setProductName(e.target.value)}
                    mask=""
                    type="text"
                    className="form-control"
                    placeholder=""
                    className="btn btn-default active me-1 mb-1"
                  ></InputMask>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">Produkt Preis</label>
                <div className="col-lg-8">
                  <InputMask
                    value={ProductPreis}
                    onChange={(e) => setProductPreis(e.target.value)}
                    type="number"
                    className="form-control"
                    placeholder=""
                    className="btn btn-default active me-1 mb-1"
                  ></InputMask>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">Add Foto</label>
                <div className="col-lg-8">
                  <InputMask
                    mask=""
                    type="file"
                    className="form-control"
                    placeholder=""
                    value={setfile}
                    onChange={(e) => {
                      if (e.target.files && e.target.files.length == 1)
                        setfile(e.target.files[0]);
                      else if (file) setfile(null);
                    }}
                  ></InputMask>
                </div>
              </div>
    
              <div className="form-group row">
                <label className="col-lg-4 col-form-label">
                 Add Category
                </label>
                <div className="col-lg-8">
                  <Input
                    type="select"
                    className="form-control"
                    id="exampleSelect"
                    value={selectedCategory.uuid}
                    onChange={(e) => {
                      const c = CategoryState.find(
                        (category) => category.uuid == e.target.value
                      );
                      setselectedCategory(c || emptyCategory);
                    }}
                  >
                    <option value={""}>Select a Category</option>
                    {CategoryState.filter(
                      (category) =>
                        !categories.find(
                          (category2) => category.uuid == category2.uuid
                        )
                    ).map((category, index) => (
                      <option value={category.uuid}>{category.name}</option>
                    ))}
                  </Input>
                </div>
                {/* <button
                  onClick={() => setcategories([...categories, selectedCategory])}
                  disabled={!selectedCategory.uuid}
                  className="btn btn-default active me-1 mb-1"
                >
                  Add Category
                </button> */}
              </div>
    
              <div className="form-group row">
                <div className="col-lg-8">
                  <div className="table-responsive">
                    <table className="table table-hover mb-0 text-inverse">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>CategoryName</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td></td>
                          <td>
                            {" "}
                            {categories.map((category) => (
                              <>
                                {" "}
                                {category.name}{" "}
                                <button
                               style={{ border: "none" , backgroundColor: "initial" }} 
                                  onClick={() => {
                                    setcategories(
                                      categories.filter(
                                        (category2) =>
                                          category.uuid != category2.uuid
                                      )
                                    );
                                  }}
                                >
                                <i className="icon-trash , text-success"  ></i>
                                </button>
                                <br />
                              </>
                            ))}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="hljs-wrapper"></div>
                </div>
              </div>
            </form>
    
            <button
              onClick={() => setcategories([...categories, selectedCategory])}
              disabled={!selectedCategory.uuid}
              className="btn btn-default active me-1 mb-1"
            >
              Add Category
            </button>


          </div>
            
      <br />

<FormGroup>

  <ul>
    <br />

    {categories
      .filter((x) => x.categoriesUids == categoryID)
      .map((cat) => (
        <li key={cat.categoriesUids}>
          {" "}
          {cat.name}{" "}
        
        </li>
      ))}
  </ul>
 
</FormGroup>
{/* <button
        onClick={addhandler}
        className="btn btn-default active me-1 mb-1"
        className="btn btn-primary btn-lg"
      >
        AddProduct
      </button>
             */}
     








    </ModalBody>

    
    
    <ModalFooter>
    <button className="btn btn-white" onClick={() => toggleModal('modalDialog')}>Close</button>
    <button     onClick={addhandler} className="btn btn-success" className="btn btn-sm btn-primary btn-me">AddProduct</button>
    </ModalFooter>
    </Modal>)

      
    



    
  }















  









////////alte code 09.09.2021 11:07

// import React, { useState } from 'react';


// import SweetAlert from 'react-bootstrap-sweetalert';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from'reactstrap';
// import Highlight from "react-highlight";
// import InputMask from "react-input-mask";
// import { useHistory } from "react-router";
// import { useDispatch,useSelector } from "react-redux";
// import { warningAlert } from "../../slices/alertSlice";
// import { confirmAlert } from "../../slices/alertSlice";
// import { successAlert } from "../../slices/alertSlice";
// import { FormGroup, Input, Label } from "reactstrap";
// import { authHeader, config, uploadHeader } from "../../helpers";
// import { useEffect } from 'react';


// const emptyCategory = {
//   uuid: "",
// };


// export const AddProductForm = (props) => {
//     const [showForm, setshowForm] = useState(false);
//     const dispatch = useDispatch();
//     const user = useSelector((state) => state.user);
//     const [file, setfile] = useState();
//     const [ProductId, setProductId] = useState("");
//     const [ProductName, setProductName] = useState("");
//     const [ProductPreis, setProductPreis] = useState("");
//     const [categoryID, setcategoryID] = useState("");
//     const [CategoryState, setCategoryState] = useState([]);
//     const [selectedCategory, setselectedCategory] = useState(emptyCategory);
//     const [categories, setcategories] = useState([]);
//     const [products, setProducts] = useState([]);
//     const [filterProducts, setfilterProducts] = useState([]);
//     const [filterString, setfilterString] = useState("");
//     const history = useHistory();
//     const [posMobileSidebarToggled, setposMobileSidebarToggled] = useState(false);
//     const [modalPosItem, setmodalPosItem] = useState(false);


//   const [modalIsOpen, setmodalIsOpen] = useState(false)
    
  
//     const togglePosMobileSidebar = () => {
//       setposMobileSidebarToggled(!posMobileSidebarToggled);
//     };
//     const toggleModal = (name) => {
//       switch (name) {
//         case "modalPosItem":
//           setmodalPosItem(!modalPosItem);
//           break;
//         default:
//           break;
//       }
//     };
  

   
// // const toggleModal = () =>{
// //   setshowForm(!showForm);
// // }


//     const toggleForm = () => {
//         setshowForm(!showForm);
//     }



//     React.useEffect(() => {
//       getProducts().then((res) => {
//         setProducts(res);
//         setfilterProducts(res);
//       });
//     }, []);
  
//     async function getProducts() {
//       const requestOptions = {
//         method: "GET",
//         headers: authHeader(),
//       };
  
//       return fetch(`${config.apiUrl}/Products/GetProducts`, requestOptions)
//         .then((res) => res.json())
//         .then((res) => {
//           return res;
//         });
//     }
  
//     async function upload(uuid) {
//       if (file) {
//         const formData = new FormData();
//         formData.append("uploadedFile", file);
//         return fetch(
//           `${config.apiUrl}/Products/AddImage?productId=${encodeURIComponent(
//             uuid
//           )}`,
//           {
//             method: "POST",
//             // body should be file
//             body: formData,
//             // body: JSON.stringify(formData)
//             headers: uploadHeader(),
//             // headers: authHeader(),
//           }
//         );
//       }
//     }
  
//     async function ProductHinzu(inputs) {
//       const response = await fetch(`${config.apiUrl}/Products/AddNewProduct`, {
//         method: "POST",
//         body: JSON.stringify(inputs),
//         headers: authHeader(),
//       });
  
//       const data = await response.json();
  
//       await getProducts().then(async (res) => {
//         const temp = file && res.find((prod) => inputs.name == prod.name);
  
//         console.log(temp);
//         if (temp) {
//           await upload(temp.uuid);
//         }
//       });
//       getProducts().then(async (res) => {
//         setProducts(res);
//       });
  
//       setProductId("");
//       setProductName("");
//       setProductPreis("");
//       setcategories([]);
  
//       dispatch(successAlert("Produkte ist erfolgreisch hinzugefüget"));
  
//       console.log("error");
//     }

  
//     useEffect(() => {
//       if (filterString) {
//         setfilterProducts(
//           products.filter((prod) =>
//             prod.name.toLowerCase().includes(filterString.toLowerCase())
//           )
//         );
  
//         console.log(filterProducts);
//       } else {
//         setfilterProducts(products);
//       }
//     }, [filterString, products]);
  
//     const addhandler = () => {
//       // console.log(getProduct);
//       //producte.name === ProductName
//       // getProduct.includes(ProductName)
//       //console.log(getProduct.name);
//       //console.log(ProductName);
//       // console.log((getProduct.slice(1,2)));
  
//       const proname = products.map((prod33) => prod33.name);
//       // console.log(proname);
//       // console.log(getProduct);
  
//       if (!proname.includes(ProductName)) {
//         ProductHinzu({
//           designerId: user.uuid,
//           name: ProductName,
//           netPrice: ProductPreis,
//           currency: "",
//           totalPrice: 0,
//           quantity: 0,
//           photos: [],
//           categoriesUids: categories.map((category) => category.uuid),
//         });
  
//         setProductId("");
//         setProductName("");
//         setProductPreis("");
//         // setcategories([]);
//       } else {
//         dispatch(
//           warningAlert("Sie dürfen nicht Duplicate Produkte Name angeben")
//         );
  
//         console.log("error");
//       }
//     };
  
//     React.useEffect(() => {
//       const requestOptions = {
//         method: "GET",
//         headers: authHeader(),
//       };
  
//       fetch(`${config.apiUrl}/Categories/GetCategoriesList`, requestOptions)
//         .then((response) => response.json())
//         .then((response) => {
//           setCategoryState(response);
//           console.log(response);
//         });
//     }, []);
  
//     console.log("cattttttttttt", CategoryState);
  
//     // const editProduct = () => {
  
//     // } ;
  
//     const deletehandler = (uuid) => {
//       const requestOptions = {
//         method: "DELETE",
//         headers: authHeader(),
//         body: JSON.stringify({
//           uuid: encodeURIComponent(uuid),
//         }),
//       };
//       fetch(`${config.apiUrl}/Products/DeleteProduct`, requestOptions).then(
//         (response) => {
//           console.log(response);
//           getProducts().then((res) => setProducts(res));
//         }
//       );
//     };
  
//     const confirmDelete = (uuid) => {
//       dispatch(
//         confirmAlert({
//           message: "Deleting this Product cannot be undone.",
//           confirm: () => {
//             deletehandler(uuid);
//           },
//         })
//       );
//     };


//     if (!showForm) {
//       return (
//         <button
//         // toggle={() => this.toggleModal('modalDialog')}
//             onClick={toggleForm}
//             className="btn btn-default active me-1 mb-1"
//             className="btn btn-primary btn-lg"
//         >
//             AddProduct
//         </button>
     
//       );
//     }
//     else return (
//         <SweetAlert showCancel={true}
//             confirmBtnText="Add Product"
//             confirmBtnBsStyle="primary"
//             cancelBtnBsStyle="default"
//             title="Add Product"
//             onConfirm={() => {

           
//                 // send AddProduct request
                
//                 toggleForm();
//             }}
//             onCancel={() => {
//                 // reset all inputs

//                 toggleForm();
//             }}
            
//         >
//             {/* Here put the Input fields etc. */}
            

//             <div className="col-xl-6">
//             <form className="form-horizontal form-bordered">
//               <div className="form-group row">
//                 <label className="col-lg-4 col-form-label">Produkt ID</label>
//                 <div className="col-lg-8">
//                   <InputMask
//                     value={ProductId}
//                     onChange={(e) => setProductId(e.target.value)}
//                     className="form-control"
//                     type="text"
//                     placeholder="Produkt ID"
//                     className="btn btn-default disabled me-1 mb-1"
//                   ></InputMask>
//                 </div>
//               </div>
//               <div className="form-group row">
//                 <label className="col-lg-4 col-form-label">Produkt Name</label>
//                 <div className="col-lg-8">
//                   <InputMask
//                     value={ProductName}
//                     onChange={(e) => setProductName(e.target.value)}
//                     mask=""
//                     type="text"
//                     className="form-control"
//                     placeholder=""
//                     className="btn btn-default active me-1 mb-1"
//                   ></InputMask>
//                 </div>
//               </div>
//               <div className="form-group row">
//                 <label className="col-lg-4 col-form-label">Produkt Preis</label>
//                 <div className="col-lg-8">
//                   <InputMask
//                     value={ProductPreis}
//                     onChange={(e) => setProductPreis(e.target.value)}
//                     type="number"
//                     className="form-control"
//                     placeholder=""
//                     className="btn btn-default active me-1 mb-1"
//                   ></InputMask>
//                 </div>
//               </div>
//               <div className="form-group row">
//                 <label className="col-lg-4 col-form-label">ZugehörgieFoto</label>
//                 <div className="col-lg-8">
//                   <InputMask
//                     mask=""
//                     type="file"
//                     className="form-control"
//                     placeholder=""
//                     value={setfile}
//                     onChange={(e) => {
//                       if (e.target.files && e.target.files.length == 1)
//                         setfile(e.target.files[0]);
//                       else if (file) setfile(null);
//                     }}
//                   ></InputMask>
//                 </div>
//               </div>
    
//               <div className="form-group row">
//                 <label className="col-lg-4 col-form-label">
//                   Category Hinzufügen
//                 </label>
//                 <div className="col-lg-8">
//                   <Input
//                     type="select"
//                     className="form-control"
//                     id="exampleSelect"
//                     value={selectedCategory.uuid}
//                     onChange={(e) => {
//                       const c = CategoryState.find(
//                         (category) => category.uuid == e.target.value
//                       );
//                       setselectedCategory(c || emptyCategory);
//                     }}
//                   >
//                     <option value={""}>Select a Category</option>
//                     {CategoryState.filter(
//                       (category) =>
//                         !categories.find(
//                           (category2) => category.uuid == category2.uuid
//                         )
//                     ).map((category, index) => (
//                       <option value={category.uuid}>{category.name}</option>
//                     ))}
//                   </Input>
//                 </div>
//                 {/* <button
//                   onClick={() => setcategories([...categories, selectedCategory])}
//                   disabled={!selectedCategory.uuid}
//                   className="btn btn-default active me-1 mb-1"
//                 >
//                   Add Category
//                 </button> */}
//               </div>
    
//               <div className="form-group row">
//                 <div className="col-lg-8">
//                   <div className="table-responsive">
//                     <table className="table table-hover mb-0 text-inverse">
//                       <thead>
//                         <tr>
//                           <th>#</th>
//                           <th>CategoryName</th>
//                         </tr>
//                       </thead>
//                       <tbody>
//                         <tr>
//                           <td></td>
//                           <td>
//                             {" "}
//                             {categories.map((category) => (
//                               <>
//                                 {" "}
//                                 {category.name}{" "}
//                                 <button
//                                   className="btn btn-sm btn-white w-60px"
//                                   onClick={() => {
//                                     setcategories(
//                                       categories.filter(
//                                         (category2) =>
//                                           category.uuid != category2.uuid
//                                       )
//                                     );
//                                   }}
//                                 >
//                                   Remove
//                                 </button>
//                                 <br />
//                               </>
//                             ))}
//                           </td>
//                         </tr>
//                       </tbody>
//                     </table>
//                   </div>
//                   <div className="hljs-wrapper"></div>
//                 </div>
//               </div>
//             </form>
    
//             <button
//               onClick={() => setcategories([...categories, selectedCategory])}
//               disabled={!selectedCategory.uuid}
//               className="btn btn-default active me-1 mb-1"
//             >
//               Add Category
//             </button>


//           </div>
            
//       <br />

// <FormGroup>

//   <ul>
//     <br />

//     {categories
//       .filter((x) => x.categoriesUids == categoryID)
//       .map((cat) => (
//         <li key={cat.categoriesUids}>
//           {" "}
//           {cat.name}{" "}
        
//         </li>
//       ))}
//   </ul>
 
// </FormGroup>
// <button
//         onClick={addhandler}
//         className="btn btn-default active me-1 mb-1"
//         className="btn btn-primary btn-lg"
//       >
//         AddProduct
//       </button>
            

//         </SweetAlert>
//     )
// }