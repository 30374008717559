import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router";


const PrivateRoute = (props) => {
	const user = useSelector(state => state.user);

	if (!user.loggedIn) return (user.loaded ? <Redirect from={props.path} to={{pathname:'/user/login', state:{goBack: props.path}}}/> : null);

	return (
		<Route
			key={props._key}
			path={props.path}
			exact={props.exact}
			component={props.component}
		/>
	)
}

			export default PrivateRoute;
