import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, ButtonToggle, Col, FormGroup, Input, Label, Table } from 'reactstrap';
import { Panel, PanelBody, PanelHeader } from '../../components/panel/panel';
import userTypes, { canEditTo } from '../../helpers/userTypes';
import { getUsersByType } from '../../slices/userListSlice';
import AddUserModal from './AddUserModal';
import EditUserType from './EditUserType';

const UserList = (props) => {
  const dispatch = useDispatch();

  const user = useSelector(state => state.user);
  const userList = useSelector(state => state.userList);
  const [filterUserType, setfilterUserType] = useState('');
  const [showAddUser, setshowAddUser] = useState(false);

  const toggleAddUserModal = () => {
    setshowAddUser(!showAddUser);
  }

  useEffect(() => {
    if (!userList.items && !userList.loading && !userList.error && !userList.rejected) dispatch(getUsersByType());
  }, [userList.items]);

  return (
    <Panel>
      <PanelHeader noButton={true}>
        <div className="d-flex align-items-center">
          User List
        </div>
      </PanelHeader>
      <PanelBody>
        {canEditTo(user.userType)?.length>0 && <AddUserModal isOpen={showAddUser} toggle={() => toggleAddUserModal()} />}
        <FormGroup row>
          <Label for="filterUserType" sm="1">Show All</Label>
          <Col sm="2">
            <Input type="select" name="selectUserType" id="filterUserType"
              disabled={user.userId == props.userId}
              value={filterUserType} onChange={e=>setfilterUserType(e.target.value)}
            >
              <option value={''}>User Roles</option>
              {userTypes.map(type => <option key={type} value={type}>{type}</option>)}
            </Input>
          </Col>
        </FormGroup>
        <br/>
        {userList.items && <Table striped bordered>
          <thead>
            <tr>
              <th scope="row" className="w-25">Username</th>
              <th className="w-25">Email</th>
              <th className="w-50">User Type</th>
            </tr>
          </thead>
          <tbody>
            {(filterUserType ? userList.items.filter(item=>item.userType == filterUserType) : userList.items)
            .map((item, index) => 
              <tr key={item.id}>
                <td scope="row">{item.userName}</td>
                <td>{item.email}</td>
                <td>
                  <EditUserType userId={item.id} userType={item.userType} />
                </td>
                {/* <td>
                  <button disabled={user.userId == item.id} className="btn btn-danger btn-xs">delete user</button>
                </td> */}
              </tr>
            )}
          </tbody>
        </Table>}
        <br/>
        {canEditTo(user.userType)?.length>0 && <button onClick={() => toggleAddUserModal()} className="btn btn-sm btn-success btn-lg">Add User</button>}
      </PanelBody>
    </Panel>
  );
}

export default UserList;