import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { authHeader, config } from '../../helpers';
import { confirmAlert } from '../../slices/alertSlice';
import './categorieStyle.css';
import { failureAlert, successAlert } from '../../slices/alertSlice';
import { getcategoryListe } from '../../slices/CategorySlice';




export default function Deletetegories(props) {

  const dispatch = useDispatch();


  const { categorieName, categoryuuid } = props;


  // delete 
  const _delete = (uuid) => {
    const requestOptions = {
      method: 'DELETE',
      accept: 'application/json',
      headers: authHeader(),
      body: JSON.stringify({
        uuid: uuid,
      })
    };
    return fetch(`${config.apiUrl}/Categories/DeleteCategory?uuid=${encodeURIComponent(uuid)}`, requestOptions)
      .then(res => res.json())
      .then(data => {
        if (!data.ok) { dispatch(failureAlert(data.message)) }
        else { dispatch(successAlert(`Successfully to Delete  ${categorieName} from Category List `)) }
      })

      .then(res => { dispatch(getcategoryListe()) })

  }


  function _confirmDelete() {

    dispatch(confirmAlert({
      // title: 'Confirm to Delete',
      message: `Are you sure to Delete ${categorieName}`,
      onConfirm: () => { return (_delete(categoryuuid)) }
    }));
  };


  return <React.Fragment>
    <button title="Delete Category"  className={"btn btn-danger btn-icon  btn-circle btn-lg"} onClick={() => _confirmDelete()} ><i className="fa  fa-trash-alt"  /></button>
  </React.Fragment>


}

