
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { authService } from '../services/authService';
import { authHeader } from '../helpers/authHeader';
import { config } from '../config/config';
import { login, logout } from './userSlice';

export const register = createAsyncThunk(
  'Authenticate/register',
  async (payload) => {
    console.log('hello from register AsyncThunk');
    localStorage.clear();
    const requestOptions = {
      method: 'POST',
      headers: authHeader(),
      body: JSON.stringify(payload),
      //body: JSON.stringify({
      //  username: payload.username,
      //  email: payload.email,
      //  phoneNumber: payload.phoneNumber,
      //  password: payload.password,
      //  rePassword: payload.rePassword,
      //}),
    };
    return fetch(`${config.apiUrl}/Authenticate/register`, requestOptions).then(res => res.json());
  }
);

const initialState = {};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    acknowledgeFailure: (state, action) => {
      state.failure = false;
    },
    reset: () => initialState
  },
  extraReducers: {
    // register reducers
    [register.pending]: (state, action) => {
      return {
        pending: true,
      };
    },
    [register.rejected]: (state, action) => {

      return {
        error: action.error,
      };
    },
    [register.fulfilled]: (state, action) => {
      const payload = action.payload;

      switch (payload.status) {
        case "Success":
        case 200:
        case 201:
        case 202:
        case 203:
        case 204:
          return {
            success: true,
            message: payload.message
          };
        case 400:
        case 401:
        default:
          return {
            failure: true,
            message: payload.message,
            errors: payload.errors,
          };
      }
    },
    //[login.pending]: () => initialState,
    // [logout.pending]: () => initialState,
  }
});

export const { acknowledgeFailure, reset } = registerSlice.actions;

export default registerSlice.reducer;
