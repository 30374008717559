import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


// import { authService } from '../services/authService';
import { authHeader } from '../helpers/authHeader';
import { uploadHeader }from '../helpers/authHeader' ;
import { config } from '../config/config';
import { logout } from './userSlice';
import { failureAlert, infoAlert, successAlert } from './alertSlice';

/**
 * @param {withClosed, status} args
 */
export const getOrders = createAsyncThunk(
  '/Orders/GetOrders',
  async (args, thunkAPI) => {

    const requestOptions = {
      method:'GET',
      headers: authHeader(),
      // body: JSON.stringify({
      //   uuid: encodeURIComponent(uuid)
      // })
    };
    let url = `${config.apiUrl}/Orders/GetOrders`;
    if (args) {
      url += `?withClosed=${args.withClosed}&status=${args.status}`;
    }
    return fetch(url, requestOptions).then(res => res.json());
  }
)
/**
 * @param {userUuid, withClosed, status} args
 */
export const getUserOrders = createAsyncThunk(
  '/Orders/GetUserOrders',
  /**
   * 
   * @param {userUuid, withClosed, status} args 
   * @param {*} thunkAPI 
   * @returns 
   */
  async (args, thunkAPI) => {

    const requestOptions = {
      method:'GET',
      headers: authHeader(),
      // body: JSON.stringify({
      //   uuid: encodeURIComponent(uuid)
      // })
    };
    let url = `${config.apiUrl}/Orders/GetUserOrders`;
    if (args) {
      url += `?userUuid=${args.userUuid}&withClosed=${args.withClosed}&status=${args.status}`;
    }
    return fetch(url, requestOptions).then(res => res.json());
  }
)

const initialState = {};

export const orderListSlice = createSlice({
  name: 'orderList',
  initialState,
  reducers:{
    reset : () => {
      return initialState
    },
  },

  extraReducers: {
    
    [getOrders.pending]: (state,action) => {
      return {
        ...action.meta.arg,
        loading: true,
      };
    },
    [getOrders.fulfilled]: (state,action) => {
      if (action.payload.status && action.payload.status >= 400) {
          return {
            ...action.meta.arg,
            error: true,
          }
      } else return {
        ...action.meta.arg,
        items: action.payload,
      }
    },
    [getOrders.rejected]: (state,action) => {
      console.log('getOrders rejected:');
      console.log(action.payload);
      return {
        ...action.meta.arg,
        rejected: true,
      }
    },

    [getUserOrders.pending]: (state,action) => {
      return {
        ...action.meta.arg,
        loading: true,
      };
    },
    [getUserOrders.fulfilled]: (state,action) => {
      if (action.payload.status && action.payload.status >= 400) {
          return {
            ...action.meta.arg,
            error: true,
          }
      } else return {
        ...action.meta.arg,
        items: action.payload,
      }
    },
    [getUserOrders.rejected]: (state,action) => {
      console.log('getOrders rejected:');
      console.log(action.payload);
      return {
        ...action.meta.arg,
        rejected: true,
      }
    },

    [logout.pending]:(state,action)=>{
      return initialState;
    },
  },


  
})
         
  export const { reset } = orderListSlice.actions;

  export default orderListSlice.reducer;
  
