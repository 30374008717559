import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import countryList from '../../helpers/countryList';
import userTypes, { canEdit, canEditTo, clientType } from '../../helpers/userTypes';
import { confirmAlert } from '../../slices/alertSlice';
import { addUser, editUserRole } from '../../slices/userListSlice';

export default function AddUserModal(props) {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

	const [username, setUsername] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [rePassword, setRePassword] = useState('');
	const [phoneCountryCode, setPhoneCountryCode] = useState('');
	const [phoneAreaCode, setPhoneAreaCode] = useState('');
	const [phoneNumber, setPhoneNumber] = useState('');

  const [userType, setuserType] = useState(clientType);

  const valid = username && password && password === rePassword;

  const resetInputs = () => {
    setUsername('');
    setuserType(clientType);
    setEmail('');
    setPassword('');
    setRePassword('');
    setPhoneAreaCode('');
    setPhoneCountryCode('');
    setPhoneNumber('');
  }
  const submit = () => {
    if (valid) {
      dispatch(addUser({
        username: username,
        email: email,
        phoneNumber: {
          countryCode: phoneCountryCode,
          areaCode: phoneAreaCode,
          number: phoneNumber
        },
        password: password,
        rePassword: rePassword,
        userRole: userType
      }));
      
    }
    resetInputs();
  }
  const close = () => {
    resetInputs();
    props.toggle();
  }

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.toggle()} >
      <ModalHeader toggle={() => props.toggle()} close={<button className="btn-close" onClick={() => props.toggle()}></button>}>
        <strong>Add User</strong><br /> 
      </ModalHeader >
      
      <ModalBody>
        <div className="register-content">
          <div key="username" className="mb-3">
            <label className="mb-2">Username <span className="text-danger">*</span></label>
            <input type="text" className="form-control fs-13px" placeholder="Username"
              value={username} onChange={e => setUsername(e.target.value)}
            />
          </div>
          <div key="email" className="mb-3">
            <label className="mb-2">Email <span className="text-danger">*</span></label>
            <input type="email" className="form-control fs-13px" placeholder="Email address"
              value={email} onChange={e => setEmail(e.target.value)}
            />
          </div>
          <div key="phoneNumber" className="mb-3">
            <label className="mb-2">Phone Number <span className="text-danger">*</span></label>
            <div className="row gx-3">
              <div className="col-md-4 mb-2 mb-md-0">
                <FormGroup>
                  <Input type="select" name="select" id="exampleSelect" value={phoneCountryCode} onChange={e => setPhoneCountryCode(e.target.value)}>
                    <option key="countryCode" value={''}>Country Code</option>
                    {countryList.map((country, index) => <option key={`${country.country} (+${country.code}`} value={country.code}>{country.country} (+{country.code})</option>)}
                  </Input>
                </FormGroup>
              </div>
              <div className="col-md-4">
                <input type="text" className="form-control fs-13px" placeholder="Area Code"
                  value={phoneAreaCode} onChange={e => setPhoneAreaCode(e.target.value)}
                />
              </div>
              <div className="col-md-4">
                <input type="text" className="form-control fs-13px" placeholder="Number"
                  value={phoneNumber} onChange={e => setPhoneNumber(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <label className="mb-2">Password <span className="text-danger">*</span></label>
            <input type="password" className="form-control fs-13px" placeholder="Password"
              value={password} onChange={e => setPassword(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <label className="mb-2">Re-enter Password <span className="text-danger">*</span></label>
            <input type="password" className="form-control fs-13px" placeholder="Re-enter Password"
              value={rePassword} onChange={e => setRePassword(e.target.value)}
            />
            {password !== rePassword && <label style={{ color: 'red' }}>Password and Confirmation must be equal.</label>}
          </div>
          <div key="UserType" className="mb-3">
            <label className="mb-2">User Type</label> 
            <FormGroup> 
              <Input
                type="select" name="selectUserType" value={userType} onChange={e=>setuserType(e.target.value)}>
                {canEditTo(user.userType).map(type => <option key={type} value={type}>{type}</option>)}
              </Input>
            </FormGroup>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <button className="btn btn-white" onClick={() => close()}>Close</button>
        <button onClick={() => submit()} disabled={!valid} className="btn btn-success">Add User</button>
      </ModalFooter>
    </Modal>
  )
}