import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Input, Row } from 'reactstrap';
import userTypes, { canEdit, canEditTo } from '../../helpers/userTypes';
import { confirmAlert } from '../../slices/alertSlice';
import { editUserRole } from '../../slices/userListSlice';

export default function EditUserType(props) {
  const user = useSelector(state => state.user);
  const dispatch = useDispatch();

  const [userType, setuserType] = useState(props.userType);

  const save = () => {
    if (user.userId == props.userId) dispatch(confirmAlert({
      message:'Are you sure you want to edit your own userType? You may not be able to reverse this action.',
      onConfirm:() => dispatch(editUserRole({userId:props.userId,userType:userType})),
    }));
    else dispatch(editUserRole({userId:props.userId,userType:userType}))
  }

  return (<>
    {(canEdit(user.userType, props.userType) && user.userId !== props.userId) ? <FormGroup row>
      <Col className="w-50">
        <Input 
          // disabled={user.userId == props.userId}
          type="select" name="selectUserType" id={`userType_${props.userId}`} value={userType} onChange={e=>setuserType(e.target.value)}>
          {canEditTo(user.userType).map(type => <option key={type} value={type}>{type}</option>)}
        </Input>
      </Col>
      <Col className="w-50">
        <button disabled={props.userType == userType} className="btn btn-success btn-xs" onClick={()=>setuserType(props.userType)}>Undo</button> {' '}
        <button disabled={props.userType == userType} className="btn btn-primary btn-xs" onClick={()=>save()}>Save User Type</button>
      </Col>
    </FormGroup>
    : props.userType}
  </>)
}