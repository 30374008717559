export const ownerType = 'Owner'
export const adminType = 'Admin';
export const designerType = 'Designer';
export const workerType = 'Worker';
export const driverType = 'Driver';
export const clientType = 'Client';

const userTypes = [
  ownerType,
  adminType,
  designerType,
  workerType,
  driverType,
  clientType,
];


export const canEdit = (userType, targetType) => {
  switch (userType) {
    case ownerType:
      return true;
    case adminType:
      return (targetType !== ownerType);
    default:
      return false;
  }
}
export const canEditTo = (userType) => {
  switch (userType) {
    case ownerType:
      return userTypes;
    case adminType:
      return userTypes.filter(type => type != ownerType);
    default:
      return null;
  }
}

export default userTypes;