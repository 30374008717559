import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { privateRoutes, publicRoutes } from "../../config/app-route";
import findRoute from "../../helpers/findRoute";
import ExtraError from "../../pages/extra/extra-error";
import { loadUser, logout } from "../../slices/userSlice";
import PrivateRoute from "./PrivateRoute";


const AppRouter = (props) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const user = useSelector(state => state.user);
	const [error404, seterror404] = useState(undefined);

	useEffect(() => {
		dispatch(loadUser());
		seterror404(!findRoute(location.pathname));
	
		setInterval(()=> {
			if (user.loggedIn && user.loaded && new Date(user.expiration) <= new Date(Date.now())) dispatch(logout());
		}, 5000);
	}, []);
	useEffect(() => {
		seterror404(!findRoute(location.pathname));
	}, [location.pathname]);

	if (!user.loaded || error404 === undefined) return null;

	if (error404) return <ExtraError />;
	else return privateRoutes.map((privateRoute, index) =>
			<PrivateRoute
				key={index}
				_key={index}
				path={privateRoute.path}
				exact={privateRoute.exact}
				component={privateRoute.component}
			/>
		).concat(publicRoutes.map((publicRoute, index) =>
			<Route
				key={index + privateRoutes.length}
				path={publicRoute.path}
				exact={publicRoute.exact}
				component={publicRoute.component}
			/>
		));
}

export default AppRouter;
