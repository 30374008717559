
export const orderStatus = {
  Pending: 'Pending',
  Accepted: 'Accepted',
  Recieved: 'Received',
  InProgress: 'InProgress',
  OutForDelivery: 'OutForDelivery',
  Delivered: 'Delivered',
  Canceled: 'Canceled',
  Failed: 'Failed',
}

export const orderStatusArray = Object.values(orderStatus);

export default orderStatus;