import React from 'react';
import { withRouter } from 'react-router-dom';
import { privateRoutes, publicRoutes } from '../../config/app-route.jsx';
import findRoute from '../../helpers/findRoute.js';
import AlertNotification from '../alert/AlertNotification.jsx';
import { AppSettings } from './../../config/app-settings.js';
import AppRouter from './AppRouter.jsx';

function setTitle(path) {
	const route = findRoute(path);
	document.title = (route) ? 'FlowraPortal | ' + route.title : 'FlowraPortal | Admin Dashboard';
}

class Content extends React.Component {
	componentDidMount() {
		setTitle(this.props.history.location.pathname);
	}
	componentWillMount() {
		this.props.history.listen(() => {
			setTitle(this.props.history.location.pathname);
		});
	}

	render() {
		return (
			<AppSettings.Consumer>
				{({ appContentClass }) => (
					<div className={'app-content ' + appContentClass}>
						<AppRouter />
						<AlertNotification />
					</div>
				)
				}
			</AppSettings.Consumer>
		)
	}
}

export default withRouter(Content);
