import React from "react";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { FormGroup, Input, Label, ListGroup } from "reactstrap";
import { authHeader, config, uploadHeader } from "../../helpers";
import { useDispatch } from "react-redux";
import { warningAlert } from "../../slices/alertSlice";
import { confirmAlert } from "../../slices/alertSlice";
import { successAlert } from "../../slices/alertSlice";
import { getProductListe } from "../../slices/ProduktListeSlice";
import { getcategoryListe } from "../../slices/CategorySlice";
import { Deleteprod } from "../../slices/prodSlice";
import { EditProductForm } from "./EditProductForm";
import { Button, ButtonToggle, Col,  Table } from 'reactstrap';
// import  AddProductForm from "./AddProductForm";




import {
  Panel,
  PanelHeader,
  PanelBody,
} from "../../components/panel/panel.jsx";
import { reset } from "../../slices/registerSlice";
import { Link } from "react-router-dom";
// import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
import {
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import Highlight from "react-highlight";
import InputMask from "react-input-mask";
import { useHistory, useLocation } from "react-router";
import ProductInfo from "./ProductInfo";
import { AddProductForm } from "./AddProductForm";
// import ProductInfo from './ProductInfo';


const emptyCategory = {
  uuid: "",
};


function Product(props) {
  const location = useLocation();
  const dispatch = useDispatch();
  const produktliste = useSelector((state) => state.produktliste.data);
  const productListeState = useSelector((state) => state.produktliste);
  const Category = useSelector((state) => state.Category.data);
  const CategoryState = useSelector((state) => state.Category);
  const prod = useSelector((state) => state.prod.data);
  const [filterCategoryUuid,setfiltercategory] =useState('');
  // const urlCategoryUuid = new URLSearchParams(location.search).get('categoryUuid');
  // const [selectedCategory, setselectedCategory] = useState(emptyCategory);
  // const produktliste = useSelector(state => state.produktliste);

  const [posMobileSidebarToggled, setposMobileSidebarToggled] = useState(false);
  const [modalPosItem, setmodalPosItem] = useState(false);
 const history =useHistory();

console.log("history" ,history);
console.log("location" ,location);
  // const [editData, seteditData] = useState(null);

  // const togglePosMobileSidebar = () => {
  //   setposMobileSidebarToggled(!posMobileSidebarToggled);
  // };

  const toggleModal = (name) => {
    switch (name) {
      case "modalPosItem":
        setmodalPosItem(!modalPosItem);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    
    // TODO: Get urlCategoryUuid from URL-Parameters
    const urlCategoryUuid = new URLSearchParams(location.search).get('categoryUuid');
    console.log("urlCategoryUuid", urlCategoryUuid)
    console.log("location", location);
    setfiltercategory(urlCategoryUuid );

     
  },[Category]);


  

  useEffect(() => {
    if (!produktliste && !productListeState.status && !productListeState.error ) dispatch(getProductListe());

    // dispatch(getProductById(urlId));
    // dispatch(getProductList());
  }, [produktliste]);

  useEffect(() => {
    if (!Category && !CategoryState.status && !CategoryState.error) dispatch(getcategoryListe());

    // dispatch(getProductById(urlId));
    // dispatch(getProductList());
  }, [Category]);

  const confirmDelete = (uuid) => {
    // dispatch(Deleteprod(uuid));
    // dispatch(getcategoryListe());


    dispatch(
      confirmAlert({
        message: "Deleting this Product cannot be undone.",
        onConfirm: () => {
          dispatch(Deleteprod(uuid));
        },
      })
    );

    dispatch(getcategoryListe());
  };


 
  
  
 
  return (

    
    <div>
      <Link to={`/product/Product?categoryUuid=${filterCategoryUuid}`} ></Link>

      {/* {editData && <EditProductForm data={editData} clearData={()=>seteditData(null)} />} */}

      <Panel>
        <PanelHeader >
          Product Detials <span className="badge bg-success ms-5px"></span>
        </PanelHeader>
       

        
        <PanelBody>
        <FormGroup row>
          <Label htmlFor="filtercategory" sm="1">Search by..</Label>
          <Col sm="2">
            <Input type="select" name="filtercategory" id="filtercategory"
            value={filterCategoryUuid}
            onChange={e=>setfiltercategory(e.target.value)}
            >
              <option value={''}>AllProduct</option>
              {Category && Category.map (ct1 => <option key={ct1.uuid} value={ct1.uuid}>{ct1.name}</option>)};
              {/* {userTypes.map(type => <option key={type} value={type}>{type}</option>)} */}
            </Input>
          </Col>
        </FormGroup>
        <br/>
{/* <div>location= {location.pathname}</div> */}
          <div className="table-responsive">
            <table className="table table-striped mb-0 align-middle">
              <thead>
                <tr>
                  <th>Photos</th>
                  <th>ProductName</th>
                  <th>Category</th>
                  <th>ProductPreis</th>
                  <th width="1%"></th>
                </tr>
              </thead>
              {/* {produktliste &&
                  produktliste.map((prod1) => { */}
                   {/* {(filtercategory ?  produktliste && produktliste.filter(cat44 => (Category.filter(cat333 => cat333.name.tolowerCase().includes(filtercategory.tolowerCase()).uuid)) == cat44.categoriesUids) : produktliste) */}
              <tbody>
                {produktliste && (
                  filterCategoryUuid
                  ? produktliste.filter(product2 => product2.categoriesUids.includes(filterCategoryUuid))
                  : produktliste
                 ).map((prod1) => {
                    const photo = prod1.photos.find((photo) => photo.main);
                    return (
                      <tr>
                        <td>
                          {/* {photo && <img src={`${config.webUrl}/${photo.url}`} width={50}  />} */}
                          {photo && (
                            <img
                              src={`${config.webUrl}/images/Products/${photo.uuid}.jpg`}
                              width={50}
                            />
                          )}
                        </td>
                        <td>     <Link
                              to={`/product/ProductInfo?id=${prod1.uuid}`}
                              onClick={() => toggleModal("modalPosItem")}
                            >
                              {/* <div className="img" style={{backgroundImage: 'url(/assets/img/pos/product-6.jpg)'}}></div> */}
                              {/* <div className="text"> */}
                              {prod1.name}
                              {/* <div className="desc">avocado, milk, vanilla</div> */}
                              {/* <div className="price">{prod.price} {prod.currency}</div> */}
                              {/* </div> */}
                            </Link></td>

                        <td>
                          {prod1.categoriesUids &&
                            prod1.categoriesUids.map((cat7) => (
                              <li>
                                <span className="fa-li">
                                  <i className="fas fa-camera-retro fa-lg"></i>
                                </span>{" "}
                                {Category &&
                                  Category.find(
                                    (category11) => category11.uuid == cat7
                                  )?.name}
                              </li>
                            ))}
                        </td>

                        <td>{prod1.netPrice}</td>

                        <td nowrap="true">
                          {/* <Link to="/table/basic" className="btn btn-sm btn-primary w-60px me-1" onClick={()=>edithandler(prod)}>Edit</Link>
                            <Link to="/table/basic" className="btn btn-sm btn-white w-60px"  onClick={()=>confirmDelete(prod.uuid)} >Delete</Link> */}

                       
                        {/* <button onClick={ editbutton }>

                        </button> */}

                        {/* <button onClick= {()=>{seteditData(prod1)}}> */}
                        <EditProductForm data={prod1}/>

                        {/* </button> */}
                          
                           {/* <button
                            className="btn btn-sm btn-primary w-60px me-1"
                            
                          
                          >edit
                           {editData && <EditProductForm data={editData} clearData={()=>seteditData(null)} />} 
                          
                          </button> */}

                         {/* {editData && <EditProductForm data={editData} clearData={()=>seteditData(null)} />}  */}


                          <button style={{ border: "none" , backgroundColor: "initial" }} 
                            // className="col-md-6 col-sm-6 col-xs-6 mb-10px text-ellipsis"
                            // className="btn btn-danger me-1 mb-1"
                            // className="btn btn-sm btn-white w-60px"
                            onClick={() => confirmDelete(prod1.uuid)}

                          >

                            <i  className="icon-trash h3 d-block , text-danger" style={{ fontSize: "20px"}} ></i>
                          </button>
                          {/* <Link to={`/product/ProductPage?categoriesUids=${prod1 && prod1.categoriesUids}`}  className="btn btn-sm btn-primary w-60px me-1"></Link> */}
                         

                          <div className="page-item me-auto">
                            {" "}
                            {/* <Link
                              to={`/product/ProductInfo?id=${prod1.uuid}`}
                              onClick={() => toggleModal("modalPosItem")}
                            > */}
                              {/* <div className="img" style={{backgroundImage: 'url(/assets/img/pos/product-6.jpg)'}}></div> */}
                              {/* <div className="text"> */}
                              {/* {prod1.name} */}
                              {/* <div className="desc">avocado, milk, vanilla</div> */}
                              {/* <div className="price">{prod.price} {prod.currency}</div> */}
                              {/* </div> */}
                            {/* </Link> */}
                          </div>
                          {/* <button className="btn btn-sm btn-white w-60px"  onClick={() => history.push('/product/ProductPage') }>Details</button> */}

                          {/* <Link to="/table/basic" className="btn btn-sm btn-white w-60px"  onClick={()=>confirmDelete(prod.uuid)} >Delete</Link> */}
                        </td>
                      </tr>
                    );
                  })}
                
              </tbody>
            </table>
          </div>
          <br/>
          <AddProductForm />
        {/* <link to={`/product/Product?categoryUuid=${filterCategoryUuid}`}></link> */}
       
          {/* <EditProductForm/> */}
          
        </PanelBody>
        <div className="hljs-wrapper">
          {/* <Highlight className="html">
            {'<table className="table align-middle">' + "  ...\n" + "</table>"}
          </Highlight> */}
        </div>
      </Panel>
    </div>
  );
}

export default Product;




//ALte Code 08.09.2021..14:15 Uhr

// import React from "react";
// import { useState, useEffect, useCallback } from "react";
// import { useSelector } from "react-redux";
// import { FormGroup, Input, Label } from "reactstrap";
// import { authHeader, config, uploadHeader } from "../../helpers";
// import { useDispatch } from "react-redux";
// import { warningAlert } from "../../slices/alertSlice";
// import { confirmAlert } from "../../slices/alertSlice";
// import { successAlert } from "../../slices/alertSlice";
// import {
//   Panel,
//   PanelHeader,
//   PanelBody,
// } from "./../../components/panel/panel.jsx";
// import { reset } from "../../slices/registerSlice";
// import { Link } from "react-router-dom";
// // import { Panel, PanelHeader, PanelBody } from './../../components/panel/panel.jsx';
// import {
//   UncontrolledButtonDropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap";
// import Highlight from "react-highlight";
// import InputMask from "react-input-mask";
// import { useHistory } from "react-router";
// import ProductInfo from "./ProductInfo";
// import { AddProductForm } from "./AddProductForm";
// // import ProductInfo from './ProductInfo';

// const emptyCategory = {
//   uuid: "",
// };

// function AddProduct(props) {
//   const dispatch = useDispatch();
//   const user = useSelector((state) => state.user);
//   const [file, setfile] = useState();
//   const [ProductId, setProductId] = useState("");
//   const [ProductName, setProductName] = useState("");
//   const [ProductPreis, setProductPreis] = useState("");
//   const [categoryID, setcategoryID] = useState("");
//   const [CategoryState, setCategoryState] = useState([]);
//   const [selectedCategory, setselectedCategory] = useState(emptyCategory);
//   const [categories, setcategories] = useState([]);
//   const [products, setProducts] = useState([]);
//   const [filterProducts, setfilterProducts] = useState([]);
//   const [filterString, setfilterString] = useState("");
//   const history = useHistory();
//   const [posMobileSidebarToggled, setposMobileSidebarToggled] = useState(false);
//   const [modalPosItem, setmodalPosItem] = useState(false);

//   const togglePosMobileSidebar = () => {
//     setposMobileSidebarToggled(!posMobileSidebarToggled);
//   };
//   const toggleModal = (name) => {
//     switch (name) {
//       case "modalPosItem":
//         setmodalPosItem(!modalPosItem);
//         break;
//       default:
//         break;
//     }
//   };

//   React.useEffect(() => {
//     getProducts().then((res) => {
//       setProducts(res);
//       setfilterProducts(res);
//     });
//   }, []);

//   async function getProducts() {
//     const requestOptions = {
//       method: "GET",
//       headers: authHeader(),
//     };

//     return fetch(`${config.apiUrl}/Products/GetProducts`, requestOptions)
//       .then((res) => res.json())
//       .then((res) => {
//         return res;
//       });
//   }

//   async function upload(uuid) {
//     if (file) {
//       const formData = new FormData();
//       formData.append("uploadedFile", file);
//       return fetch(
//         `${config.apiUrl}/Products/AddImage?productId=${encodeURIComponent(
//           uuid
//         )}`,
//         {
//           method: "POST",
//           // body should be file
//           body: formData,
//           // body: JSON.stringify(formData)
//           headers: uploadHeader(),
//           // headers: authHeader(),
//         }
//       );
//     }
//   }

//   async function ProductHinzu(inputs) {
//     const response = await fetch(`${config.apiUrl}/Products/AddNewProduct`, {
//       method: "POST",
//       body: JSON.stringify(inputs),
//       headers: authHeader(),
//     });

//     const data = await response.json();

//     await getProducts().then(async (res) => {
//       const temp = file && res.find((prod) => inputs.name == prod.name);

//       console.log(temp);
//       if (temp) {
//         await upload(temp.uuid);
//       }
//     });
//     getProducts().then(async (res) => {
//       setProducts(res);
//     });

//     setProductId("");
//     setProductName("");
//     setProductPreis("");
//     setcategories([]);

//     dispatch(successAlert("Produkte ist erfolgreisch hinzugefüget"));

//     console.log("error");
//   }

//   useEffect(() => {
//     if (filterString) {
//       setfilterProducts(
//         products.filter((prod) =>
//           prod.name.toLowerCase().includes(filterString.toLowerCase())
//         )
//       );

//       console.log(filterProducts);
//     } else {
//       setfilterProducts(products);
//     }
//   }, [filterString, products]);

//   const addhandler = () => {
//     // console.log(getProduct);
//     //producte.name === ProductName
//     // getProduct.includes(ProductName)
//     //console.log(getProduct.name);
//     //console.log(ProductName);
//     // console.log((getProduct.slice(1,2)));

//     const proname = products.map((prod33) => prod33.name);
//     // console.log(proname);
//     // console.log(getProduct);

//     if (!proname.includes(ProductName)) {
//       ProductHinzu({
//         designerId: user.uuid,
//         name: ProductName,
//         netPrice: ProductPreis,
//         currency: "",
//         totalPrice: 0,
//         quantity: 0,
//         photos: [],
//         categoriesUids: categories.map((category) => category.uuid),
//       });

//       setProductId("");
//       setProductName("");
//       setProductPreis("");
//       // setcategories([]);
//     } else {
//       dispatch(
//         warningAlert("Sie dürfen nicht Duplicate Produkte Name angeben")
//       );

//       console.log("error");
//     }
//   };

//   React.useEffect(() => {
//     const requestOptions = {
//       method: "GET",
//       headers: authHeader(),
//     };

//     fetch(`${config.apiUrl}/Categories/GetCategoriesList`, requestOptions)
//       .then((response) => response.json())
//       .then((response) => {
//         setCategoryState(response);
//         console.log(response);
//       });
//   }, []);



//   // const editProduct = () => {

//   // } ;

//   const deletehandler = (uuid) => {
//     const requestOptions = {
//       method: "DELETE",
//       headers: authHeader(),
//       body: JSON.stringify({
//         uuid: encodeURIComponent(uuid),
//       }),
//     };
//     fetch(`${config.apiUrl}/Products/DeleteProduct`, requestOptions).then(
//       (response) => {
//         console.log(response);
//         getProducts().then((res) => setProducts(res));
//       }
//     );
//   };

//   const confirmDelete = (uuid) => {
//     dispatch(
//       confirmAlert({
//         message: "Deleting this Product cannot be undone.",
//         confirm: () => {
//           deletehandler(uuid);
//         },
//       })
//     );
//   };

//   const edithandler = (prod) => {
//     console.log(prod);
//     setProductName(prod.name);
//     setProductPreis(prod.netPrice);
//     setProductId(prod.uuid);
//     setcategoryID(prod.categoriesUids);
//     setcategories(
//       prod.categoriesUids.map((uuid) =>
//         CategoryState.find((category) => category.uuid == uuid)
//       )
//     );
//     console.log(prod);
//     // getfotosbeiId(prod.uuid);
//   };

//   const Save = () => {
//     const requestOptions = {
//       method: "POST",
//       headers: authHeader(),
//       body: JSON.stringify({
//         uuid: encodeURIComponent(ProductId),
//         designerId: user.uuid,
//         name: ProductName,
//         netPrice: 0,
//         currency: "",
//         netPrice: ProductPreis,
//         quantity: 0,
//         photos: [],
//         categoriesUids: categories.map((category) => category.uuid),
//       }),
//     };

//     fetch(`${config.apiUrl}/Products/UpdateProduct`, requestOptions).then(
//       (response) => {
//         console.log(response);
//         getProducts().then((res) => setProducts(res));

//         if (file) {
//           upload(ProductId);
//         }
//       }
//     );
//   };

//   //  useEffect( () => {
//   //   Save ();

//   // }, [file,ProductName,ProductPreis]);

//   return (
//     <div>
//       <AddProductForm />
//       <div className="col-xl-6">
//         <form className="form-horizontal form-bordered">
//           <div className="form-group row">
//             <label className="col-lg-4 col-form-label">Produkt ID</label>
//             <div className="col-lg-8">
//               <InputMask
//                 value={ProductId}
//                 onChange={(e) => setProductId(e.target.value)}
//                 className="form-control"
//                 type="text"
//                 placeholder="Produkt ID"
//                 className="btn btn-default disabled me-1 mb-1"
//               ></InputMask>
//             </div>
//           </div>
//           <div className="form-group row">
//             <label className="col-lg-4 col-form-label">Produkt Name</label>
//             <div className="col-lg-8">
//               <InputMask
//                 value={ProductName}
//                 onChange={(e) => setProductName(e.target.value)}
//                 mask=""
//                 type="text"
//                 className="form-control"
//                 placeholder=""
//                 className="btn btn-default active me-1 mb-1"
//               ></InputMask>
//             </div>
//           </div>
//           <div className="form-group row">
//             <label className="col-lg-4 col-form-label">Produkt Preis</label>
//             <div className="col-lg-8">
//               <InputMask
//                 value={ProductPreis}
//                 onChange={(e) => setProductPreis(e.target.value)}
//                 type="number"
//                 className="form-control"
//                 placeholder=""
//                 className="btn btn-default active me-1 mb-1"
//               ></InputMask>
//             </div>
//           </div>
//           <div className="form-group row">
//             <label className="col-lg-4 col-form-label">ZugehörgieFoto</label>
//             <div className="col-lg-8">
//               <InputMask
//                 mask=""
//                 type="file"
//                 className="form-control"
//                 placeholder=""
//                 value={setfile}
//                 onChange={(e) => {
//                   if (e.target.files && e.target.files.length == 1)
//                     setfile(e.target.files[0]);
//                   else if (file) setfile(null);
//                 }}
//               ></InputMask>
//             </div>
//           </div>

//           <div className="form-group row">
//             <label className="col-lg-4 col-form-label">
//               Category Hinzufügen
//             </label>
//             <div className="col-lg-8">
//               <Input
//                 type="select"
//                 className="form-control"
//                 id="exampleSelect"
//                 value={selectedCategory.uuid}
//                 onChange={(e) => {
//                   const c = CategoryState.find(
//                     (category) => category.uuid == e.target.value
//                   );
//                   setselectedCategory(c || emptyCategory);
//                 }}
//               >
//                 <option value={""}>Select a Category</option>
//                 {CategoryState.filter(
//                   (category) =>
//                     !categories.find(
//                       (category2) => category.uuid == category2.uuid
//                     )
//                 ).map((category, index) => (
//                   <option value={category.uuid}>{category.name}</option>
//                 ))}
//               </Input>
//             </div>
//             <button
//               onClick={() => setcategories([...categories, selectedCategory])}
//               disabled={!selectedCategory.uuid}
//               className="btn btn-default active me-1 mb-1"
//             >
//               Add Category
//             </button>
//           </div>

//           <div className="form-group row">
//             <div className="col-lg-8">
//               <div className="table-responsive">
//                 <table className="table table-hover mb-0 text-inverse">
//                   <thead>
//                     <tr>
//                       <th>#</th>
//                       <th>CategoryName</th>
//                     </tr>
//                   </thead>
//                   <tbody>
//                     <tr>
//                       <td></td>
//                       <td>
//                         {" "}
//                         {categories.map((category) => (
//                           <>
//                             {" "}
//                             {category.name}{" "}
//                             <button
//                               className="btn btn-sm btn-white w-60px"
//                               onClick={() => {
//                                 setcategories(
//                                   categories.filter(
//                                     (category2) =>
//                                       category.uuid != category2.uuid
//                                   )
//                                 );
//                               }}
//                             >
//                               Remove
//                             </button>
//                             <br />
//                           </>
//                         ))}
//                       </td>
//                     </tr>
//                   </tbody>
//                 </table>
//               </div>
//               <div className="hljs-wrapper"></div>
//             </div>
//           </div>
//         </form>

//         <button
//           onClick={() => setcategories([...categories, selectedCategory])}
//           disabled={!selectedCategory.uuid}
//           className="btn btn-default active me-1 mb-1"
//         >
//           Add Category
//         </button>

//         {/*

// 							{/* <div className="hljs-wrapper">
// 								<Highlight className='typescript'>{
// 'import InputMask from \'react-input-mask\';\n'+
// '\n'+
// '<!-- html -->\n'+
// '<InputMask mask="9999/99/99" className="form-control" placeholder="yyyy/mm/dd"></InputMask>\n'+
// '<InputMask mask="(999) 999-999" className="form-control" placeholder="(999) 999-9999"></InputMask>\n'+
// '<InputMask mask="99-999999" className="form-control" placeholder="99-9999999"></InputMask>\n'+
// '<InputMask mask="a\\*-999-a999" className="form-control" placeholder="a*-999-a999"></InputMask>\n'+
// '<InputMask mask="999/99/9999" className="form-control" placeholder="999/99/9999"></InputMask>\n'+
// '<InputMask mask="AAA-1111-A" className="form-control" placeholder="AAA-9999-A"></InputMask>'}
// 								</Highlight>
// 							</div> */}
//       </div>

//       {/* <input type ="text" value ={ProductId} onChange={(e)=> setProductId(e.target.value)} placeholder="ProductId"  className="btn btn-default disabled me-1 mb-1" ></input><br/>
//       <input type ="text" value ={ProductName} onChange={(e)=> setProductName(e.target.value)}    placeholder="ProductName"  ></input ><br/>
//       <input type="number" value = {ProductPreis} onChange={(e)=>  setProductPreis(e.target.value)}   placeholder="ProductPreis"></input><br/>
//       <input type="file" value= {setfile} onChange={(e) => {
//         if (e.target.files && e.target.files.length==1) setfile(e.target.files[0]);
//         else if (file) setfile(null);
//       }}   placeholder="ProductFoto"></input><br/> */}

//       <br />

//       <FormGroup>
//         {/* <Input type="select" className="form-select"  id="exampleSelect"

//             value={selectedCategory.uuid}
//             onChange={e => {
//             const c = CategoryState.find(category => category.uuid == e.target.value);
//             setselectedCategory(c || emptyCategory);
//             }}
//             >
//            <option value={''} >Select a Category</option>
//            {CategoryState.filter(

//              (category) => !categories.find((category2)=>category.uuid == category2.uuid)
//             )
//            .map((category, index) => <option value={category.uuid} >{category.name}</option>
//            )}

// </Input> */}
//         <ul>
//           <br />

//           {categories
//             .filter((x) => x.categoriesUids == categoryID)
//             .map((cat) => (
//               <li key={cat.categoriesUids}>
//                 {" "}
//                 {cat.name}{" "}
//                 {/* <button onClick={()=>catupdate()} >Delete</button>
//       <button onClick={()=>catdeete()}>edit</button> */}
//               </li>
//             ))}
//         </ul>
//         {/* <input type="text" value={categoryID}></input> */}
//         {/* <input type="text" value={catName} ></input> */}
//       </FormGroup>

//       {/* <button onClick={()=>setcategories([...categories, selectedCategory])} disabled={!selectedCategory.uuid}className="btn btn-default active me-1 mb-1" >Add Category</button>  */}
//       {/* <button onClick={()=> {setcategories(categories.filter(x => x.uuid === setcategoryID)).map(x1 => x1.name)}}>Update Category</button><br/>
//            <Label for="exampleSelect">Categories: </Label> <br/>
//            {categories.map((category) => <> {category.name} <button onClick={()=>{
//             setcategories(categories.filter(category2 => category.uuid != category2.uuid))
//           }}>Remove</button><br/></>)}
// <br/> */}

//       {/* <div className="table-responsive">
// 									<table className="table table-hover mb-0 text-inverse" >
// 										<thead>
// 											<tr>
// 												<th>#</th>
// 												<th>CategoryName</th>

// 											</tr>
// 										</thead>
// 										<tbody>
// 											<tr>
// 												<td>1</td>
// 												<td>     {categories.map((category) => <> {category.name} <button className="btn btn-sm btn-white w-60px" onClick={()=>{
//             setcategories(categories.filter(category2 => category.uuid != category2.uuid))
//           }}>Remove</button><br/></>)}</td>

// 											</tr>

// 										</tbody>
// 									</table>
// 								</div>
//                 <div className="hljs-wrapper">

// 							</div> */}

//       <button
//         onClick={addhandler}
//         className="btn btn-default active me-1 mb-1"
//         className="btn btn-primary btn-lg"
//       >
//         AddProduct
//       </button>

//       {/* <button onClick={Save}>Save</button>  */}

//       <br />

//       {/* <input type="text" placeholder="Search by ProductName" onChange= {e =>setfilterString(e.target.value)}>
//    </input> */}
//       <br />

//       <Panel>
//         <PanelHeader>
//           Product Detials <span className="badge bg-success ms-5px">NEW</span>
//         </PanelHeader>
//         <PanelBody>
//           <div className="table-responsive">
//             <table className="table table-striped mb-0 align-middle">
//               <thead>
//                 <tr>
//                   <th>Photos</th>
//                   <th>ProdukteNanme</th>
//                   <th>ZugehörgieCategory</th>
//                   <th>ProduktPreis</th>
//                   <th width="1%"></th>
//                 </tr>
//               </thead>

//               <tbody>
//                 {filterProducts.map((prod) => {
//                   const photo = prod.photos.find((photo) => photo.main);
//                   return (
//                     <tr>
//                       <td>
//                         {/* {photo && <img src={`${config.webUrl}/${photo.url}`} width={50}  />} */}
//                         {photo && (
//                           <img
//                             src={`${config.webUrl}/images/Products/${photo.uuid}.jpg`}
//                             width={50}
//                           />
//                         )}
//                       </td>
//                       <td>{prod.name}</td>

//                       <td>
//                         {prod.categoriesUids.map((cat7) => (
//                           <li>
//                             {" "}
//                             {
//                               CategoryState.find(
//                                 (category) => category.uuid == cat7
//                               )?.name
//                             }
//                           </li>
//                         ))}
//                       </td>

//                       <td>{prod.netPrice}</td>

//                       <td nowrap="true">
//                         {/* <Link to="/table/basic" className="btn btn-sm btn-primary w-60px me-1" onClick={()=>edithandler(prod)}>Edit</Link>
//                             <Link to="/table/basic" className="btn btn-sm btn-white w-60px"  onClick={()=>confirmDelete(prod.uuid)} >Delete</Link> */}

//                         <button
//                           className="btn btn-sm btn-primary w-60px me-1"
//                           onClick={() => edithandler(prod)}
//                         >
//                           Edit
//                         </button>
//                         <button
//                           className="btn btn-sm btn-white w-60px"
//                           onClick={() => confirmDelete(prod.uuid)}
//                         >
//                           Delete
//                         </button>
//                         <div className="page-item me-auto">
//                           {" "}
//                           <Link
//                             to={`/product/ProductInfo?id=${prod.uuid}`}
//                             onClick={() => toggleModal("modalPosItem")}
//                           >
//                             {/* <div className="img" style={{backgroundImage: 'url(/assets/img/pos/product-6.jpg)'}}></div> */}
//                             {/* <div className="text"> */}
//                             {prod.name}
//                             {/* <div className="desc">avocado, milk, vanilla</div> */}
//                             {/* <div className="price">{prod.price} {prod.currency}</div> */}
//                             {/* </div> */}
//                           </Link>
//                         </div>
//                         {/* <button className="btn btn-sm btn-white w-60px"  onClick={() => history.push('/product/ProductPage') }>Details</button> */}

//                         {/* <Link to="/table/basic" className="btn btn-sm btn-white w-60px"  onClick={()=>confirmDelete(prod.uuid)} >Delete</Link> */}
//                       </td>
//                     </tr>
//                   );
//                 })}
//               </tbody>
//             </table>
//           </div>
//         </PanelBody>
//         <div className="hljs-wrapper">
//           <Highlight className="html">
//             {'<table className="table align-middle">' + "  ...\n" + "</table>"}
//           </Highlight>
//         </div>
//       </Panel>

//       {/* <ul>
// <br/>
//   {filterProducts.map(prod => (
//     <li key={prod.uuid}> {" "}
//       {prod.uuid} {"  "}
//       <img src={`${config.webUrl}/images/Products/${prod.uuid}.jpg`} width={50} />
//       {prod.name} {"{prod.categoriesUids.map(key9 => (
// <li> {""}
// {CategoryState.find(category => category.uuid == cat7 ).name}

// </li>

// ))}  "}
//       {prod.netPrice} {" "}
//       <button onClick={()=>confirmDelete(prod.uuid)} >Delete</button>
//       <button onClick={()=>edithandler(prod)}>edit</button>
//     </li>

//   ))}

// </ul>  */}

//       {/* <ProductInfo getprodukte={getProducts}/> */}
//     </div>
//   );
// }

// export default AddProduct;
