import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';


// import { authService } from '../services/authService';
import { authHeader } from '../helpers/authHeader';
import { uploadHeader }from '../helpers/authHeader' ;
import { config } from '../config/config';
import { logout } from './userSlice';
import { failureAlert, infoAlert, successAlert } from './alertSlice';

export const getUsersByType = createAsyncThunk(
  '/Users/GetUsersByType',
  async (type, thunkAPI) => {

    const requestOptions = {
      method:'GET',
      headers: authHeader(),
      // body: JSON.stringify({
      //   uuid: encodeURIComponent(uuid)
      // })
    };
    if (type) return fetch(`${config.apiUrl}/Users/GetUsersByType?type=${type}`, requestOptions).then(res => res.json());
    else return fetch(`${config.apiUrl}/Users/GetUsersByType`, requestOptions).then(res => res.json());
  }
)
export const editUserRole = createAsyncThunk(
  '/Authenticate/editUserRole',
  async ({userId, userType}, thunkAPI) => {

    const requestOptions = {
      method:'PATCH',
      headers: authHeader(),
      // body: JSON.stringify({
      //   uuid: encodeURIComponent(uuid)
      // })
    };
    return fetch(`${config.apiUrl}/Authenticate/editUserRole?userId=${userId}&roleName=${userType}`, requestOptions).then(res => res.json());
  }
)
export const addUser = createAsyncThunk(
  '/Authenticate/adduser',
  async (data, thunkAPI) => {

    const requestOptions = {
      method:'POST',
      headers: authHeader(),
      body: JSON.stringify(data),
    };
    return fetch(`${config.apiUrl}/Authenticate/adduser`, requestOptions).then(res => {
      if (res.ok) thunkAPI.dispatch(successAlert("User Added successfully"));
      else thunkAPI.dispatch(failureAlert(`Error ${res.status}: ${res.statusText}`));
      return res.json();
    });
  }
)

export const userListStatus = {
  loading: 'loading',
  success: 'success',
  failure: 'failure',
};
const initialState = {};

export const userListSlice = createSlice({
  name: 'userList',
  initialState,
  reducers:{
    reset : () => {
      return initialState
    },
  },

  extraReducers: {
    
    [getUsersByType.pending]: (state,action) => {
      return {
        type: action.meta.arg,
        loading: true,
      };
    },

    [getUsersByType.fulfilled]: (state,action) => {
      if (action.payload.status && action.payload.status >= 400) {
          console.log('sth went wrong in getUsersByType:');
          console.log(action.payload);
          return {
            type: action.meta.arg,
            error: true,
          }
      } else return {
        type: action.meta.arg,
        items: action.payload,
      }
        
    },

    [getUsersByType.rejected]:(state,action)=>{
      console.log('getUsersByType rejected:');
      console.log(action.payload);
      return {
        type: action.meta.arg,
        rejected: true,
      }
    },

    [editUserRole.fulfilled]:(state, action)=>{
      return initialState;
    },
    [addUser.fulfilled]: (state,action)=>{
      return initialState;
    },

    [logout.pending]:(state,action)=>{
      return initialState;
    },
  },


  
})
         
  export const { reset } = userListSlice.actions;

  export default userListSlice.reducer;
  
