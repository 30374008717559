
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// import { authService } from '../services/authService';
import { authHeader } from '../helpers/authHeader';
import { config } from '../config/config';
import { login, logout } from './userSlice';

export const alertType = {
  confirm: 'CONFIRM_ALERT',
  primary: 'PRIMARY_ALERT',
  failure: 'FAILURE_ALERT',
  success: 'SUCCESS_ALERT',
  warning: 'WARNING_ALERT',
  info: 'INFO_ALERT',
}

const initialState = {};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    dismissAlert: () => initialState,
    confirmAlert: (state, action) => {
      return {
        type: alertType.confirm,
        ...action.payload,
        
      };
    },
    failureAlert: (state, action) => {
      return {
        type: alertType.failure,
        message: action.payload
      };
    },
    infoAlert: (state, action) => {
      return {
        type: alertType.info,
        message: action.payload
      };
    },
    primaryAlert: (state, action) => {
      return {
        type: alertType.primary,
        message: action.payload
      };
    },
    successAlert: (state, action) => {
      return {
        type: alertType.success,
        message: action.payload
      };
    },
    warningAlert: (state, action) => {
      return {
        type: alertType.warning,
        message: action.payload
      };
    },
  },
  extraReducers: {
  }
});

export const { confirmAlert, dismissAlert, failureAlert, infoAlert, primaryAlert, successAlert, warningAlert } = alertSlice.actions;

export default alertSlice.reducer;
