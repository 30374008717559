
export function authHeader() {
  // return authorization header with jwt token

  let user = JSON.parse(localStorage.getItem('user'));

  let header = {
    'Content-Type': 'application/json'
  };

  if (user && user.token) {
    header['Authorization'] = 'Bearer ' + user.token;
  }

  return header;

}

export function uploadHeader() {
  // return authorization header with jwt token

  let user = JSON.parse(localStorage.getItem('user'));

  let header = {
    // 'Content-Type': 'multipart/form-data'
  };

  if (user && user.token) {
    header['Authorization'] = 'Bearer ' + user.token;
  }

  return header;

}



