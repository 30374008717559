import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// import { authService } from '../services/authService';
import { authHeader } from "../helpers/authHeader";
import { uploadHeader } from "../helpers/authHeader";
import { config } from "../config/config";
import { logout } from "./userSlice";

export const prodStatus = {
  loading: "PRODUCTBYIDLOADING",
  success: "PRODUCTBYIDSUCCESS",
  failure: "PRODUCTBYIDFAILURE",
};

export const getprodByID = createAsyncThunk(
  "/Products/GetProductById",
  async (uuid) => {
    const requestOptions = {
      method: "GET",
      headers: authHeader(),
      // body: JSON.stringify({
      //   uuid: encodeURIComponent(uuid)
      // })
    };

    return fetch(
      `${
        config.apiUrl
      }/Products/GetProductById?productUuId=${encodeURIComponent(uuid)}`,
      requestOptions
    ).then((res) => res.json());
  }
);

export const uploadByID = createAsyncThunk(
  "/Products/AddImage",

  async ({ uuid, file }) => {
    if (file) {
      const formData = new FormData();
      formData.append("uploadedFile", file);
      return fetch(
        `${config.apiUrl}/Products/AddImage?productId=${encodeURIComponent(
          uuid
        )}`,
        {
          method: "POST",
          // body should be file
          body: formData,
          headers: uploadHeader(),
        }
      );
    }
  }
);

export const DeleteImg = createAsyncThunk(
  "/Products/DeleteImage",
  async ({ uuid, imguuid }) => {
    const requestOptions = {
      method: "DELETE",
      headers: authHeader(),
    };
    fetch(
      `${config.apiUrl}/Products/DeleteImage/?productUuid=${uuid}&imageUuid=${imguuid}`,
      requestOptions
    ).then((response) => {
      console.log(response);
    });
  }
);

export const Deleteprod = createAsyncThunk(
  "/Products/DeleteProduct",
  async (uuid) => {
    const requestOptions = {
      method: "DELETE",
      headers: authHeader(),
      body: JSON.stringify({
        uuid: encodeURIComponent(uuid),
      }),
    };
    fetch(`${config.apiUrl}/Products/DeleteProduct`, requestOptions).then(
      (response) => {
        console.log(response);
      }
    );
  }
);

const initialState = {};
// const loadedState = {
//   loaded: true
// }

export const prodSlice = createSlice({
  name: "prod",
  initialState,
  reducers: {
    reset: () => {
      return initialState;
    },
  },

  extraReducers: {
    [getprodByID.pending]: (state, action) => {
      state.status = prodStatus.loading;
    },

    [getprodByID.fulfilled]: (state, action) => {
      state.status = prodStatus.success;
      state.data = action.payload;
    },

    [getprodByID.rejected]: (state, action) => {
      state.status = prodStatus.failure;
    },

    [uploadByID.pending]: (state, action) => {
      state.status = prodStatus.loading;
    },
    [uploadByID.fulfilled]: (state, action) => {
      return initialState;
    },

    [uploadByID.rejected]: (state, action) => {
      return initialState;
    },

    [DeleteImg.pending]: (state, action) => {
      state.status = prodStatus.loading;
    },
    [DeleteImg.fulfilled]: (state, action) => {
      return initialState;
    },

    [DeleteImg.rejected]: (state, action) => {
      return initialState;
    },

    [Deleteprod.pending]: (state, action) => {
      state.status = prodStatus.loading;
    },
    [Deleteprod.fulfilled]: (state, action) => {
      return initialState;
    },

    [Deleteprod.rejected]: (state, action) => {
      return initialState;
    },
  },

  [logout.pending]: (state, action) => {
    return initialState;
  },
});

export const { reset } = prodSlice.actions;

export default prodSlice.reducer;

//   Alte Code 13.09.2021##########

//   import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// // import { authService } from '../services/authService';
// import { authHeader } from '../helpers/authHeader';
// import { uploadHeader }from '../helpers/authHeader' ;
// import { config } from '../config/config';
// import { logout } from './userSlice';

// export const prodStatus = {
//   loading: 'PRODUCTBYIDLOADING',
//   success: 'PRODUCTBYIDSUCCESS',
//   failure: 'PRODUCTBYIDFAILURE',
// };

// export const getprodByID = createAsyncThunk(
//   '/Products/GetProductById',
//   async (uuid) => {

//     const requestOptions = {
//       method:'GET',
//       headers: authHeader(),
//       // body: JSON.stringify({
//       //   uuid: encodeURIComponent(uuid)
//       // })
//     };

//     return fetch(`${config.apiUrl}/Products/GetProductById?productUuId=${encodeURIComponent(uuid)}`, requestOptions)
//     .then(res  =>  res.json()

//     )

//   }
// )

// export const uploadByID = createAsyncThunk(

//     '/Products/AddImage' ,

//   async({uuid ,file}) => {
//     if (file) {
//       const formData = new FormData();
//       formData.append('uploadedFile', file);
//     return fetch(`${config.apiUrl}/Products/AddImage?productId=${encodeURIComponent(uuid)}` , {
//         method : 'POST' ,
//         // body should be file
//         body: formData,
//         headers : uploadHeader(),
//       });

//     }})

// export const DeleteImg = createAsyncThunk(

//   '/Products/DeleteImage' ,
//   async({uuid ,imguuid}) => {

//     const requestOptions = {
//             method: "DELETE",
//             headers: authHeader(),
//             // body: JSON.stringify({
//             //   uuid: encodeURIComponent(uuid),
//             // }),
//           };
//           fetch(`${config.apiUrl}/Products/DeleteImage/?productUuid=${uuid}&imageUuid=${imguuid}`, requestOptions).then(
// (response) => {
//   console.log(response);

// }

//           )}
// )

//    const initialState = {};
//   // const loadedState = {
//   //   loaded: true
//   // }

// export const prodSlice = createSlice({
//   name: 'prod',
//   initialState,
//   reducers:{
//     reset : () => {
//       return initialState
//     },
//   },

//   extraReducers: {

//     [getprodByID.pending]: (state,action) => {
//         state.status =prodStatus.loading;

//     },

//     [getprodByID.fulfilled]: (state,action) => {
//       state.status=prodStatus.success;
//       state.data=action.payload;

//     },

//     [getprodByID.rejected]:(state,action)=>{
//       state.status=prodStatus.failure;
//     },

//     [uploadByID.pending]: (state,action) => {
//       state.status =prodStatus.loading;
//     },
//     [uploadByID.fulfilled]: (state,action) => {
//       return initialState;
//     },

//     [uploadByID.rejected]:(state,action)=>{
//       return initialState;
//     },

//     [DeleteImg.pending]: (state,action) => {
//       state.status =prodStatus.loading;
//     },
//     [DeleteImg.fulfilled]: (state,action) => {
//       return initialState;
//     },

//     [DeleteImg.rejected]:(state,action)=>{
//       return initialState;
//     },

//   },

//   [logout.pending]:(state,action)=>{
//     return initialState;
//   },

// })

//   export const { reset } = prodSlice.actions;

//   export default prodSlice.reducer;

//   Alte Code 09.09.2021  16:40

//   import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

// // import { authService } from '../services/authService';
// import { authHeader } from '../helpers/authHeader';
// import { uploadHeader }from '../helpers/authHeader' ;
// import { config } from '../config/config';
// import { logout } from './userSlice';

// export const prodStatus = {
//   loading: 'PRODUCTBYIDLOADING',
//   success: 'PRODUCTBYIDSUCCESS',
//   failure: 'PRODUCTBYIDFAILURE',
// };

// export const getprodByID = createAsyncThunk(
//   '/Products/GetProductById',
//   async (uuid) => {

//     const requestOptions = {
//       method:'GET',
//       headers: authHeader(),
//       // body: JSON.stringify({
//       //   uuid: encodeURIComponent(uuid)
//       // })
//     };

//     return fetch(`${config.apiUrl}/Products/GetProductById?productUuId=${encodeURIComponent(uuid)}`, requestOptions)
//     .then(res  =>  res.json()

//     )

//   }
// )

// export const uploadByID = createAsyncThunk(

//     '/Products/AddImage' ,

//   async({uuid ,file}) => {
//     if (file) {
//       const formData = new FormData();
//       formData.append('uploadedFile', file);
//     return fetch(`${config.apiUrl}/Products/AddImage?productId=${encodeURIComponent(uuid)}` , {
//         method : 'POST' ,
//         // body should be file
//         body: formData,
//         headers : uploadHeader(),
//       });

//     }})

//    const initialState = {};
//   // const loadedState = {
//   //   loaded: true
//   // }

// export const prodSlice = createSlice({
//   name: 'prod',
//   initialState,
//   reducers:{
//     reset : () => {
//       return initialState
//     },
//   },

//   extraReducers: {

//     [getprodByID.pending]: (state,action) => {
//         state.status =prodStatus.loading;

//     },

//     [getprodByID.fulfilled]: (state,action) => {
//       state.status=prodStatus.success;
//       state.data=action.payload;

//     },

//     [getprodByID.rejected]:(state,action)=>{
//       state.status=prodStatus.failure;
//     },

//     [uploadByID.pending]: (state,action) => {
//       state.status =prodStatus.loading;
//     },
//     [uploadByID.fulfilled]: (state,action) => {
//       return initialState;
//     },

//     [uploadByID.rejected]:(state,action)=>{
//       return initialState;
//     },
//   },

//   [logout.pending]:(state,action)=>{
//     return initialState;
//   },

// })

//   export const { reset } = prodSlice.actions;

//   export default prodSlice.reducer;
